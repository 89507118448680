import { useEffect, useState } from "react";
import { Modal, Pagination, Radio } from 'antd'
import { request, transMediaList } from "./utils";
import MediaItemnew from "./MediaItemnew";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Upload,Tabs } from "antd";
import { Checkbox} from 'antd';
import type { TabsProps } from 'antd';
import axios from "axios";
import $ from 'jquery' ;


const options = [
  { label: "全部", value: "all" },
  { label: "视频", value: "video" },
  { label: "音频", value: "audio" },
  { label: "图片", value: "image" },
];
const items: TabsProps['items'] = [
  {
    key: '1',
    label: `资源`,
    children: ``,
  },
  {
    key: '2',
    label: `已选中`,
    children: ``,
  }
  
];

const specialeffects = [
    { title: "复古", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/m1.jpg" },
	{ title: "灰调", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/m2.jpg" },
	{ title: "青阶", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/m3.jpg" },
	{ title: "蓝调", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/m4.jpg" },
	{ title: "暗红", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/m5.jpg" },
	{ title: "暗淡", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/m6.jpg" },
	{ title: "灰橙", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/m7.jpg" },
	{ title: "通透", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/m8.jpg" },
	{ title: "高调", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pf1.jpg" },
	{ title: "富士", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pf2.jpg" },
	{ title: "暖色", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pf3.jpg" },
	{ title: "柯达", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pf4.jpg" },
	{ title: "复古", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pf5.jpg" },
	{ title: "反转", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pf6.jpg" },
	{ title: "红外", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pf7.jpg" },
	{ title: "宝丽来", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pf8.jpg" },
	{ title: "禄来", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pf9.jpg" },	
	{ title: "工业", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pfa.jpg" },	
	{ title: "灰阶", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pfb.jpg" },
	{ title: "白阶", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pfc.jpg" },	
	{ title: "清透", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pi1.jpg" },	
	{ title: "暮晚", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pi2.jpg" },	
	{ title: "秋色", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pi3.jpg" },
	{ title: "暗调", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pi4.jpg" },	
	{ title: "柔和", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pl2.jpg" },
	{ title: "春芽", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pl3.jpg" },	
	{ title: "明媚", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pl4.jpg" },	
	{ title: "小森林", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pj1.jpg" },	
	{ title: "童年", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pj2.jpg" },	
	{ title: "午后", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pj3.jpg" },	
	{ title: "花雾", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pj4.jpg" },	
	{ title: "白桃", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/delta.jpg" },	
	{ title: "林间", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/electric.jpg" },	
	{ title: "盐系", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/faded.jpg" },	
	{ title: "蓝雾", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/slowlived.jpg" },	
	{ title: "东京", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/tokoyo.jpg" },	
	{ title: "雨后", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/urbex.jpg" },	
	{ title: "温暖", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/warm.jpg" },	
	{ title: "济州岛", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/f1.jpg" },	
	{ title: "雪山", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/f2.jpg" },	
	{ title: "布达佩斯", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/f3.jpg" },	
	{ title: "蓝霜", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/f4.jpg" },	
	{ title: "尤加利", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/f5.jpg" },	
	{ title: "老街", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/f6.jpg" },	
	{ title: "咖啡", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/f7.jpg" },	
	{ title: "质感", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pv1.jpg" },	
	{ title: "天色", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pv2.jpg" },	
	{ title: "清新", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pv3.jpg" },	
	{ title: "雾气", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pv4.jpg" },	
	{ title: "高调", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pv5.jpg" },	
	{ title: "黑白", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pv6.jpg" },	
	{ title: "柔和", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/a1.jpg" },	
	{ title: "暗调", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/a2.jpg" },	
	{ title: "青空", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/a3.jpg" },	
	{ title: "蓝光", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/a4.jpg" },	
	{ title: "艳丽", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/a5.jpg" },	
	{ title: "哑光", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/a6.jpg" },	
	{ title: "老照片", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/old_photo.jpg" },	
	{ title: "反色", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/image_invert.jpg" },	
	{ title: "暗角", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/darkcorner.jpg" },	
	{ title: "强锐化", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/strongcontrast.jpg" },	
	{ title: "轻怀旧", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/memory_color.jpg" },	
	{ title: "熔铸", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/castfilter.jpg" },	
	{ title: "连环画", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/comicstrip.jpg" },	
	{ title: "黑白线描", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/blackwhitesketch.jpg" },	
	{ title: "老电影播放", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/filters/oldmovie_darkcorner.jpg" },	
];
const PAGE_SIZE = 20;
const CheckboxGroup = Checkbox.Group;

function FilterModal(props) {
  const { onSubmit, onClose, projectId,tid,tiddata } = props;
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [mediaType, setMediaType] = useState(options[0].value);
  const [tabstatus, setTabstatus] = useState('one');
  const [tabMedia, setTabMedia] = useState([]);
  const [status, setStatus] = useState("loading");
  // 定义了page变量，默认为1，可以使用setPage进行改变page的值
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [media, setMedia] = useState([]);
  const [fileList, setfileList] = useState([]);
  // const [nextToken, setNextToken] = '';
  useEffect(() => {
	  
    setStatus("loading");
	setMedia(specialeffects);
	var data=[]
	tiddata.forEach(function(item,key,array){
		var litem=[]
		litem['coverUrl']=item
	 	 data.push(litem)
	});
	
	setSelectedMedia(data)

	setStatus("done");
	tabMedialistsend(1)
   
   }, [mediaType, page,projectId,tiddata]);// eslint-disable-line
   const tabMedialistsend = (page) => {
	   var datalist=tabMedia
		specialeffects.forEach((item) => {
		  if (tiddata.indexOf(item.coverUrl) > -1) {
			  datalist.push(item)
			
		  }
		});
		setTabMedia(datalist)
   		 
   };

  const handleSubmit = async () => {
    setConfirmLoading(true);
    // 组装数据
    var valueObj = [];
	valueObj[tid]=[]
    selectedMedia.forEach((item) => {
      const coverUrl = item.coverUrl;
	  if (!valueObj[tid].includes(coverUrl)) {
		valueObj[tid].push(coverUrl)
	  }
	  // valueObj[tid] += `,${mediaId}`;
    });
	props.click(valueObj)
	onSubmit()
   
  };

  const handleMediaTypeChange = (e) => {
    console.log(e);
    setMediaType(e.target.value);
    setPage(1);
  };

  const handleClick = (item) => {
    // console.log(item);
    const index = selectedMedia.findIndex((m) => m.coverUrl === item.coverUrl);
    if (index > -1) {
      setSelectedMedia(selectedMedia.filter((_, i) => i !== index));
    } else {
      setSelectedMedia([...selectedMedia, item]);
      // console.log([...selectedMedia, item]);
    }
	var indexx = tabMedia.findIndex((m) => m.coverUrl === item.coverUrl);
	 var datalist=tabMedia
	
	if(indexx>-1){
		datalist.splice(indexx,1)
		setTabMedia(datalist);
	}else{
		datalist.push(item)
		setTabMedia(datalist);
	}
  };
 
  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);
  };
  const onTabChange = (key: string) => {
  	  if(key==="1"){
  		   setTabstatus('one')
  		  
  	  }else{
  		  setTabstatus('two')
  	  }
   
  };
  const selectedMediaIds = selectedMedia.map((m) => m.coverUrl);
  return (
    <Modal
      open
      title="滤镜"
      onOk={handleSubmit}
      onCancel={onClose}
      width={780}
      // okButtonProps={{ disabled: selectedMedia.length === 0 }}
      okText="确定"
      cancelText="取消"
      confirmLoading={confirmLoading}
    >
	<Tabs defaultActiveKey="1" items={items}  onChange={onTabChange}/>
      
     
	 
	  {status === "done"&&tabstatus==="one" &&
	    (media.length ? (
	      <div style={{ display: "flex", flexWrap: "wrap",height: "50VH",overflowY: "scroll"}} >
		  
	        {media.map((item,key) => (
			
	          <MediaItemnew
	            onClick={() => handleClick(item)}
	            selected={selectedMediaIds.indexOf(item.coverUrl) > -1}
				 key={key}
	            
	            item={item}
	          />
	        ))}
			
	      </div>
	    ) : (
	      <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
	    ))}
		{status === "done"&&tabstatus==="two" &&
		  (tabMedia.length ? (
		    <div style={{ display: "flex", flexWrap: "wrap",overflowY: "scroll"}} >
				  
		      {tabMedia.map((item,key) => (
					
		        <MediaItemnew
		          onClick={() => handleClick(item)}
		          selected={selectedMediaIds.indexOf(item.coverUrl) > -1}
				 key={key}
		          
		          item={item}
		        />
		      ))}
					
		    </div>
		  ) : (
		    <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
		  ))}
	  {status === "loading" && (
	    <div style={{ height: "615px", textAlign: "center" }}>加载中...</div>
	  )}
	  {status === "error" && (
	    <div style={{ color: "red", height: "615px", textAlign: "center" }}>
	      加载出错
	    </div>
	  )}
	 
	  
    </Modal>
  );
}

export default FilterModal;

import { memo, useEffect, useRef, useState } from 'react'
import axios from 'axios'

import { Button, Select, Input, Pagination, ConfigProvider, Upload, Tabs, Modal, message } from 'antd';
import { CloseOutlined, UploadOutlined, SyncOutlined, DeleteFilled } from '@ant-design/icons';
import zhCN from 'antd/es/locale/zh_CN';

import { formatTime, transMediaList, request } from '../.././utils'
import { ResourceManagementWrapper, UploadStyle } from './style'
import { categoryAddAPI, buiattachDelAPI, moveClassifyAPI, getCategoryListAPI } from '../../api'
import UploadComponent from '../UploadComponent/UploadComponent';

const { Search } = Input;

const styleHandle = (item) => {
    const mediaType = item.mediaBasicInfo.mediaType
    let coverUrl
    let duration
    if (mediaType === 'image') {
        coverUrl = item.fileInfoList[0].fileBasicInfo.fileUrl
    } else {
        coverUrl = item.mediaBasicInfo.coverURL || 'https://img.alicdn.com/imgextra/i2/O1CN01fRTy3n1ZM1jvBOiyO_!!6000000003179-2-tps-240-136.png';
        duration = item.fileInfoList[0].fileBasicInfo.duration
    }
    return {
        backgroundImage: `url(${coverUrl})`
    }
}
const ResourceManagement = ({ open, onOk, onClone, projectId, storeid, agentid }) => {
    /**
     * 弹框打开关闭逻辑
     */
    const [isModelShow, setIsModelShow] = useState(false)
    const ResourceManagementRef = useRef(null)
    const tabsItem = [
        { label: "全部", key: "all", value: "all" },
        { label: "视频", key: "video", value: "video" },
        { label: "音频", key: "audio", value: "audio" },
        { label: "图片", key: "image", value: "image" },
    ];
    const okHandler = async () => {
        console.log('checkMedia.length', checkMedia.length);
        if (checkMedia.length > 10) {
            return message.error("一次最多上传10个素材")
        }
        const valueObj = {};
        checkMedia.forEach((item) => {
            let mediaTypebf = ""
            if (item.mediaBasicInfo) {
                mediaTypebf = item.mediaBasicInfo.mediaType;
            } else {
                mediaTypebf = item.mediaType;
            }

            const mediaId = item.mediaId;
            if (!valueObj[mediaTypebf]) {
                valueObj[mediaTypebf] = mediaId;
            } else {
                valueObj[mediaTypebf] += `,${mediaId}`;
            }
        });
        const res = await request("AddEditingProjectMaterials", {
            ProjectId: projectId,
            MaterialMaps: encodeURIComponent(JSON.stringify(valueObj, "string")),
        });
        onOk(transMediaList(res.data.mediaInfos))
    }
    const cloneHandler = () => {
        onClone()
    }
    useEffect(() => {
        setIsModelShow(open)
    }, [open])
    useEffect(() => {
        ResourceManagementRef.current.style.display = isModelShow ? "block" : "none"
    }, [isModelShow])
    /**
     * 移动文件
     */
    const handleChange = async (value) => {
        console.log(`分类选择 ${value}`);
        if (checkMedia.length <= 0) {
            message.error("请选择文件！")
            return
        }
        const ids = checkMedia.map(item => item.id)
        try {
            const res = await moveClassifyAPI({ category: value, ids: ids })
            message.success("移动成功~")
            renewal()
        } catch (error) {
            message.error("移动文件失败！")
        }

    }
    /**全选 */
    const [checkAll, setCheckAll] = useState(false)
    const checkAllChange = () => {
        checkAll ? setCheckMedia(media) : setCheckMedia([])
    }
    useEffect(() => {
        checkAllChange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkAll])
    /**搜索 */
    const [searchtitle, setSearchtitle] = useState("")
    const onSearch = (value, _e, info) => {
        console.log(info?.source, value);
        setSearchtitle(value)
    }
    const [checkMedia, setCheckMedia] = useState([])
    /**获取数据 */
    const PAGE_SIZE = 12
    const [mediaType, setMediaType] = useState(tabsItem[0].key);
    const [page, setPage] = useState(1);
    const [media, setMedia] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [categoryid, setCategoryid] = useState(0);
    const renewal = () => {
        setCheckMedia([])
        setMediaType(tabsItem[0].key)
        setPage(1)
    }
    const getSelectaliyData = () => {
        axios.post("https://yunhong.yinwukeji.com/storeadmin/general/attachment/selectaliy", {
            limit: PAGE_SIZE,
            offset: page,
            mimetype: mediaType, // 资源类型
            order: "desc",
            storeid: storeid,
            agentid: agentid,
            categoryid: categoryid, // 文件夹id
            searchtitle: searchtitle, // 搜索
            filter: encodeURIComponent(JSON.stringify({ "mimetype": mediaType }, "string")),
            op: encodeURIComponent(JSON.stringify({ "mimetype": "LIKE" }, "string"))
        }).then((res) => {
            setMedia(res.data.mediaInfos);
            setTotalCount(res.data.totalCount)
        });
    }
    const getCategoryData = async () => {
        const res = await getCategoryListAPI({
            storeid: storeid,
            agentid: agentid
        })
        const data = res.data.rows.map((item) => {
            return { ...item, label: item.name, value: item.id }
        })
        setFolder(data)
        setFolderList([{
            name: "全部",
            id: 0
        }, ...data])
    }
    useEffect(() => {
        getSelectaliyData()
        getCategoryData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaType, page, categoryid, searchtitle]);
    /**
     * 文件选中
     */
    const mediaCheckChange = (item) => {
        const ifCheckIndex = checkMedia.findIndex((m) => m.mediaId === item.mediaId);
        ifCheckIndex === -1 ? setCheckMedia([...checkMedia, item]) : setCheckMedia(checkMedia.filter((_, i) => i !== ifCheckIndex))
    }
    /**
     * 类型选择
     */
    const tabsChange = (activeKey) => {
        setCheckMedia([])
        setMediaType(activeKey);
        setPage(1);
    };
    /**
     * 文件夹选择
     */
    const [folderList, setFolderList] = useState([])
    const [folder, setFolder] = useState([])
    const [folderActive, setFolderActive] = useState(0)
    const [folderAddShow, setFolderAddShow] = useState(false)
    const [folderAddName, setFolderAddName] = useState("")
    const folderAdd = () => {
        setFolderAddShow(true)
    }
    const modalFolderAddOk = async () => {
        console.log('folderAddName', folderAddName);
        // 添加文件夹
        if (folderAddName.length) {
            const res = await categoryAddAPI({
                storeid: storeid,
                agentid: agentid,
                name: folderAddName
            })
        } else {
            message.error("文件夹名称不能为空")
        }

        getCategoryData()
        setFolderAddShow(false)
    }
    /**
     * 分页
     */
    const onChangePagination = (pageNumber) => {
        setPage(pageNumber)
    }
    /**
     * 上传弹框 
     */
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        getSelectaliyData()
        setIsModalOpen(false);
    };
    /**
     * 删除素材
     */
    const deletingImages = async () => {
        const ids = checkMedia.map(item => item.id)
        try {
            const res = await buiattachDelAPI({ ids: ids })
            if (res.data.code === 1) {
                // renewal()
                // if(page === 1 && mediaType === tabsItem[0].key) {
                //     getSelectaliyData()
                // }
                getSelectaliyData()
                message.success("删除成功")
            }
        } catch (error) {
            message.error("删除失败")
        }

    }
    const deleteFilled = (item) => {
        console.log("删除文件夹");
    }
    return <ResourceManagementWrapper ref={ResourceManagementRef}>
        <Modal title="请上传" footer={null} destroyOnClose open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000}>
            <UploadComponent storeid={storeid} agentid={agentid} folderID={categoryid} />
            {/* {uploadComponent(storeid, agentid)} */}
        </Modal>
        <Modal title="请输入文件夹名称" destroyOnClose open={folderAddShow} onOk={modalFolderAddOk} onCancel={e => setFolderAddShow(false)} width={500}>
            <Input placeholder="请输入文件夹名称" value={folderAddName} onChange={e => setFolderAddName(e.target.value)} />
        </Modal>
        <div className='head_wrapper'>
            <div>资源库</div>
            <CloseOutlined onClick={cloneHandler} />
        </div>
        <div className="content_box">
            <div className="main_body">
                <div className="left_cont">
                    {/* <div className="classify_box folder_add_btn" onClick={folderAdd}>添加文件夹</div> */}
                    {folderList.map((item, i) => {
                        return <div
                            className={i === folderActive ? "classify_box class_active" : "classify_box"}
                            key={i}
                            onClick={() => {
                                setCheckMedia([])
                                setFolderActive(i)
                                setCategoryid(item.id)
                                setMediaType(tabsItem[0].key)
                            }}
                        >
                            {item.name}
                            {/* <DeleteFilled className="deleteFilled" onClick={e => deleteFilled(item)} /> */}
                        </div>
                    })}
                </div>
                <div className="right_cont">
                    <div className="ceiling_button">
                        <Button type="primary" icon={<SyncOutlined />} onClick={renewal}></Button>
                        <Select
                            placeholder="移动至"
                            style={{ width: 120 }}
                            onSelect={handleChange}
                            options={folder}
                        />
                        <Button type="primary" onClick={folderAdd} style={{ backgroundColor: "#18bc9c", border: "none" }}>添加文件夹</Button>
                        <Button type="primary" danger onClick={deletingImages}>删除资源</Button>
                        <Button type="primary" onClick={showModal}><UploadOutlined />上传</Button>
                        <Button type="primary" onClick={e => setCheckAll(!checkAll)}>全选</Button>
                        <Search placeholder="请输入文件名搜索" onSearch={onSearch} style={{ width: 200, marginLeft: "auto" }} />
                    </div>
                    <Tabs
                        activeKey={mediaType}
                        onChange={tabsChange}
                        // defaultActiveKey="1"
                        type="card"
                        size='small'
                        items={tabsItem}
                        style={{ marginTop: "1rem" }}
                    />
                    <div className="media_content">
                        {media.map((item, i) => {
                            const mediaType = item.mediaBasicInfo.mediaType
                            const duration = item.fileInfoList[0].fileBasicInfo.duration
                            const check = checkMedia.findIndex((m) => m.mediaId === item.mediaId) > -1 ? true : false
                            return <div className='media_item' key={i} onClick={() => mediaCheckChange(item)} style={{borderColor: check ? '#1378f9' : '#fff'}}>
                                <div className="img_video" style={styleHandle(item)}>
                                    <div className='media_check' style={{ display: check ? 'block' : 'none' }}> 已选择 </div>
                                    {mediaType !== 'image' && (
                                        <div className='duration'>{formatTime(duration)}</div>
                                    )}
                                </div>
                                <div className='title'>{item.fileInfoList[0].fileBasicInfo.fileName}</div>
                            </div>
                        })}
                    </div>
                    <div className='page_break'>
                        <ConfigProvider
                            locale={zhCN} >
                            <Pagination
                                current={page}
                                showQuickJumper
                                defaultCurrent={1}
                                total={totalCount}
                                showSizeChanger={false}
                                defaultPageSize={12}
                                onChange={onChangePagination}
                            />
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        </div>
        <div className='bottom_box'>
            <Button type="primary" onClick={okHandler} style={{ marginRight: "1rem" }}>导入</Button>
            <Button onClick={cloneHandler}>取消</Button>
        </div>
    </ResourceManagementWrapper>
}
export default memo(ResourceManagement)
import styled from "styled-components";
export const VideoejectModalWrapper = styled.div`
    .all_wrapper {
        display: flex;
        height: 500px;
        .left_wrapper {
            width: 200px;
            height: 100%;
            padding-right: 10px;
            overflow-y: scroll;
            scrollbar-width: none; /* firefox */
            -ms-overflow-style: none; /* IE 10+ */
            .classify_box {
                width: 100%;
                height: 40px;
                padding: 0 16px;
                border-radius: 4px;
                line-height: 40px;
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
                margin-bottom: 12px;
                color: #ccc;
                background-color: #161616;
            }
            .class_active {
                color: #fff;
                background-color: #000;
            }

        }
        .left_wrapper::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }
        .right_wrapper {
            width: 510px;
            height: 100%;
            overflow-y: scroll;
            scrollbar-width: none; /* firefox */
            -ms-overflow-style: none; /* IE 10+ */
            .item {
                border-radius: 4px;
                padding: 4px;
                border: 1px solid #ccc;
            }
            .selected {
                position: relative;
                border-color:rgb(19, 120, 249);
                /* border-radius: 4px; */
                .media_check {
                    height: 20px;
                    width: 48px;
                    /* opacity: .8; */
                    background-color: #1378f9;
                    box-shadow: 0 0 4px #0009;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 20px;
                    font-size: 12px;
                    position: absolute;
                    top: 6px;
                    left: 8px;
                }
            }
        }
        .right_wrapper::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }
    }
`
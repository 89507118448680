import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import 'antd/dist/antd.css'
import 'antd/dist/antd.dark.css'
import './index.css'
import './assets/icon/iconfont.css'
import App from './App'

ReactDOM.render(
  <HashRouter>
  <React.StrictMode>
  <App />
  </React.StrictMode>
   
  </HashRouter>,
  document.getElementById('root')
)

import { useEffect, useState } from "react";
import { Modal, Pagination, Radio } from 'antd'
import { request, transMediaList } from "./utils";
import MediaItemnew from "./MediaItemnew";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Upload,Tabs } from "antd";
import { Checkbox} from 'antd';
import type { TabsProps } from 'antd';
import axios from "axios";


const options = [
  { label: "全部", value: "all" },
  { label: "视频", value: "video" },
  { label: "音频", value: "audio" },
  { label: "图片", value: "image" },
];
const items: TabsProps['items'] = [
  {
    key: '1',
    label: `资源`,
    children: ``,
  },
  {
    key: '2',
    label: `已选中`,
    children: ``,
  }
  
];

const specialeffects = [
    { title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/01_AAI_FONT_COLOR_PRESET_WHITE_GRAD.png" },
    { title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/02_AAI_FONT_COLOR_PRESET_RED_GRAD.png" },
    { title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/03_AAI_FONT_COLOR_PRESET_BLUE_GRAD.png" },
    { title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/04_AAI_FONT_COLOR_PRESET_YELLOW_GRAD.png" },
    { title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/05_AAI_FONT_COLOR_PRESET_RANDOM_GRAD.png" },
    { title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/06_AAI_FONT_COLOR_PRESET_ABSTRACT_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/07_AAI_FONT_COLOR_PRESET_ABSTRACT_2.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/08_AAI_FONT_COLOR_PRESET_ABSTRACT_3.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/09_AAI_FONT_COLOR_PRESET_ANEURISM.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/10_AAI_FONT_COLOR_PRESET_BLINDS.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/11_AAI_FONT_COLOR_PRESET_BLUE_GREEN.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/12_AAI_FONT_COLOR_PRESET_BROWNS.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/13_AAI_FONT_COLOR_PRESET_BRUSHED_ALUMINIUM.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/14_AAI_FONT_COLOR_PRESET_BURNING_PAPER.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/15_AAI_FONT_COLOR_PRESET_BURNING_TRANSPARENCY.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/16_AAI_FONT_COLOR_PRESET_CD.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/17_AAI_FONT_COLOR_PRESET_CD_HALF.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/18_AAI_FONT_COLOR_PRESET_CARIBBEAN_BLUES.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/19_AAI_FONT_COLOR_PRESET_COFFEE.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/20_AAI_FONT_COLOR_PRESET_COLD_STEEL.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/21_AAI_FONT_COLOR_PRESET_COLD_STEEL_2.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/22_AAI_FONT_COLOR_PRESET_CROWN_MOLDING.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/23_AAI_FONT_COLOR_PRESET_DARK_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/24_AAI_FONT_COLOR_PRESET_DEEP_SEA.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/25_AAI_FONT_COLOR_PRESET_DEFAULT_GRAD.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/26_AAI_FONT_COLOR_PRESET_FLARE_GLOW_ANGULAR_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/27_AAI_FONT_COLOR_PRESET_FLARE_GLOW_RADIAL_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/28_AAI_FONT_COLOR_PRESET_FLARE_GLOW_RADIAL_2.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/29_AAI_FONT_COLOR_PRESET_FLARE_GLOW_RADIAL_3.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/30_AAI_FONT_COLOR_PRESET_FLARE_GLOW_RADIAL_4.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/31_AAI_FONT_COLOR_PRESET_FLARE_RADIAL_101.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/32_AAI_FONT_COLOR_PRESET_FLARE_RADIAL_102.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/33_AAI_FONT_COLOR_PRESET_FLARE_RADIAL_103.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/34_AAI_FONT_COLOR_PRESET_FLARE_RAYS_RADIAL_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/35_AAI_FONT_COLOR_PRESET_FLARE_RAYS_RADIAL_2.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/36_AAI_FONT_COLOR_PRESET_FLARE_RAYS_SIZE_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/37_AAI_FONT_COLOR_PRESET_FLARE_SIZEFAC_101.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/38_AAI_FONT_COLOR_PRESET_FOUR_BARS.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/39_AAI_FONT_COLOR_PRESET_FULL_SATURATION_SPECTRUM_CCW.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/40_AAI_FONT_COLOR_PRESET_FULL_SATURATION_SPECTRUM_CW.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/41_AAI_FONT_COLOR_PRESET_GOLDEN.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/42_AAI_FONT_COLOR_PRESET_GREENS.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/43_AAI_FONT_COLOR_PRESET_HORIZON_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/44_AAI_FONT_COLOR_PRESET_HORIZON_2.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/45_AAI_FONT_COLOR_PRESET_INCANDESCENT.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/46_AAI_FONT_COLOR_PRESET_LAND_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/47_AAI_FONT_COLOR_PRESET_LAND_AND_SEA.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/48_AAI_FONT_COLOR_PRESET_METALLIC_SOMETHING.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/49_AAI_FONT_COLOR_PRESET_NAUSEATING_HEADACHE.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/50_AAI_FONT_COLOR_PRESET_NEON_CYAN.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/51_AAI_FONT_COLOR_PRESET_NEON_GREEN.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/52_AAI_FONT_COLOR_PRESET_NEON_YELLOW.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/53_AAI_FONT_COLOR_PRESET_PASTELS.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/54_AAI_FONT_COLOR_PRESET_PASTEL_RAINBOW.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/55_AAI_FONT_COLOR_PRESET_PURPLES.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/56_AAI_FONT_COLOR_PRESET_RADIAL_EYEBALL_BLUE.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/57_AAI_FONT_COLOR_PRESET_RADIAL_EYEBALL_BROWN.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/58_AAI_FONT_COLOR_PRESET_RADIAL_EYEBALL_GREAN.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/59_AAI_FONT_COLOR_PRESET_RADIAL_GLOW_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/60_AAI_FONT_COLOR_PRESET_RADIAL_RAINBOW_HOOP.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/61_AAI_FONT_COLOR_PRESET_ROUNDED_EDGE.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/62_AAI_FONT_COLOR_PRESET_SHADOWS_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/63_AAI_FONT_COLOR_PRESET_SHADOWS_2.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/64_AAI_FONT_COLOR_PRESET_SHADOWS_3.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/65_AAI_FONT_COLOR_PRESET_SKYLINE.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/66_AAI_FONT_COLOR_PRESET_SKYLINE_POLLUTED.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/67_AAI_FONT_COLOR_PRESET_SQUARE_WOOD_FRAME.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/68_AAI_FONT_COLOR_PRESET_SUNRISE.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/69_AAI_FONT_COLOR_PRESET_THREE_BARS_SIN.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/70_AAI_FONT_COLOR_PRESET_TROPICAL_COLORS.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/71_AAI_FONT_COLOR_PRESET_TUBE_RED.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/72_AAI_FONT_COLOR_PRESET_WOOD_1.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/73_AAI_FONT_COLOR_PRESET_WOOD_2.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/74_AAI_FONT_COLOR_PRESET_YELLOW_CONTRAST.png" },
	{ title: "", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/wordart/75_AAI_FONT_COLOR_PRESET_YELLOW_ORANGE.png" },
];
const PAGE_SIZE = 20;
const CheckboxGroup = Checkbox.Group;

function FlowerCaptionsModal(props) {
  const { onSubmit, onClose, projectId,tid,tiddata,hzid,captionsdatach } = props;
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [mediaType, setMediaType] = useState(options[0].value);
  const [tabstatus, setTabstatus] = useState('one');
  const [tabMedia, setTabMedia] = useState([]);
  const [status, setStatus] = useState("loading");
  // 定义了page变量，默认为1，可以使用setPage进行改变page的值
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [media, setMedia] = useState([]);
  const [fileList, setfileList] = useState([]);
  // const [nextToken, setNextToken] = '';
  useEffect(() => {
	 
    setStatus("loading");
	setMedia(specialeffects);
	
	var data=[]
	console.log(tiddata,"tiddatatiddata")
	
	tiddata.forEach(function(item,key,array){
		var litem=[]
		litem['coverUrl']=item
	 	 data.push(litem)
	});
	console.log(data,"datadatadata")
	
	setSelectedMedia(data)

	setStatus("done");
	tabMedialistsend(1)
   }, [mediaType, page,projectId,tiddata]);// eslint-disable-line
   const tabMedialistsend = (page) => {
   	   var datalist=tabMedia
   		specialeffects.forEach((item) => {
   		  if (tiddata.indexOf(item.coverUrl) > -1) {
   			  datalist.push(item)
   			
   		  }
   		});
   		setTabMedia(datalist)
   		 
   };

 //  const handleSubmit = async () => {
 //    setConfirmLoading(true);
 //    // 组装数据
 //    var valueObj = captionsdatach;
	// console.log(captionsdatach,"captionsdatachcaptionsdatach")
	// if(!valueObj[tid]){
	// 	valueObj[tid]=[];
		
	// }

 //    selectedMedia.forEach((item) => {
 //      const coverUrl = item.coverUrl;
	//   valueObj[tid][hzid]=coverUrl
 //    });
	// console.log(valueObj,"valueObjvalueObj")
	// props.click(valueObj)
	// onSubmit()
   
 //  };
 const handleSubmit = async () => {
   setConfirmLoading(true);
   // 组装数据
   var valueObj = [];
 	valueObj[tid]=[]
   selectedMedia.forEach((item) => {
     const coverUrl = item.coverUrl;
 	  if (!valueObj[tid].includes(coverUrl)) {
 		valueObj[tid].push(coverUrl)
 	  }
 	  // valueObj[tid] += `,${mediaId}`;
   });
 	props.click(valueObj)
 	onSubmit()
  
 };
  const handleMediaTypeChange = (e) => {
    console.log(e);
    setMediaType(e.target.value);
    setPage(1);
  };

  const handleClick = (item) => {
    // console.log(item);
    const index = selectedMedia.findIndex((m) => m.coverUrl === item.coverUrl);
    if (index > -1) {
      setSelectedMedia(selectedMedia.filter((_, i) => i !== index));
    } else {
		var data=[];
		data.push(item)
      setSelectedMedia(data);
      // console.log([...selectedMedia, item]);
    }
	var indexx = tabMedia.findIndex((m) => m.coverUrl === item.coverUrl);
	 var datalist=[]
	
	if(indexx>-1){
		
		setTabMedia(datalist);
	}else{
		datalist.push(item)
		setTabMedia(datalist);
	}
  };
 
  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);
  };
  const onTabChange = (key: string) => {
  	  if(key==="1"){
  		   setTabstatus('one')
  		  
  	  }else{
  		  setTabstatus('two')
  	  }
   
  };
  const selectedMediaIds = selectedMedia.map((m) => m.coverUrl);
  console.log(selectedMediaIds,"selectedMediaIds")
  return (
    <Modal
      open
      title="花字"
      onOk={handleSubmit}
      onCancel={onClose}
      width={780}
      // okButtonProps={{ disabled: selectedMedia.length === 0 }}
      okText="确定"
      cancelText="取消"
      confirmLoading={confirmLoading}
    >
	<Tabs defaultActiveKey="1" items={items}  onChange={onTabChange}/>
	  {status === "done"&&tabstatus==="one" &&
	    (media.length ? (
	      <div style={{ display: "flex", flexWrap: "wrap",height: "50VH",overflowY: "scroll"}} >
		  
	        {media.map((item,key) => (
			
	          <MediaItemnew
	            onClick={() => handleClick(item)}
	            selected={selectedMediaIds.indexOf(item.coverUrl) > -1}
				key={key}
	            
	            item={item}
	          />
	        ))}
			
	      </div>
	    ) : (
	      <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
	    ))}
		{status === "done"&&tabstatus==="two" &&
		  (tabMedia.length ? (
		    <div style={{ display: "flex", flexWrap: "wrap",overflowY: "scroll"}} >
				  
		      {tabMedia.map((item,key) => (
					
		        <MediaItemnew
		          onClick={() => handleClick(item)}
		          selected={selectedMediaIds.indexOf(item.coverUrl) > -1}
		          key={key}
		          item={item}
		        />
		      ))}
					
		    </div>
		  ) : (
		    <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
		  ))}
				
	  {status === "loading" && (
	    <div style={{ height: "615px", textAlign: "center" }}>加载中...</div>
	  )}
	  {status === "error" && (
	    <div style={{ color: "red", height: "615px", textAlign: "center" }}>
	      加载出错
	    </div>
	  )}
	 
	  
    </Modal>
  );
}

export default FlowerCaptionsModal;

import { formatTime } from './utils'

function MediaItemnew (props) {
  const { item, onClick, selected } = props

  let coverUrl
  let title
  let duration = 0;
	coverUrl = item.coverUrl
	title = item.title
	
  const style = {
    backgroundImage: `url(${coverUrl})`
  }

  return (
    <div className={`item ${selected ? 'selected' : ''}`} onClick={onClick}>
      <div className='media_check' style={{ display: selected ? 'block' : 'none' }}>已选择</div>
      <div className='cover' style={style} />
      <div className='title'>{title}</div>
    </div>
  )
}

export default MediaItemnew

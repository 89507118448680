import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { message } from "antd";
import SearchMediaModal from "./SearchMediaModal";
import ResourceManagement from './components/ResourceManagement/ResourceManagement'
import ItemvideoModal from "./ItemvideoModal";
import { request, transMediaList, poll, requestGet } from "./utils";
import ProduceVideoModal from "./ProduceVideoModal";
import { parseJSON } from "jquery";
// import uploads from "./upload";
const FONT_FAMILIES = [
  // 'alibaba-sans', // 阿里巴巴普惠体
  // 'fangsong', // 仿宋字体
  // 'kaiti', // 楷体
  // 'SimSun', // 宋体
  // 'siyuan-heiti', // 思源黑体
  'siyuan-songti', // 思源宋体
  'wqy-zenhei-mono', // 文泉驿等宽正黑
  'wqy-zenhei-sharp', // 文泉驿点阵正黑
  'wqy-microhei', // 文泉驿微米黑
  'zcool-gaoduanhei', // 站酷高端黑体
  'zcool-kuaile', // 站酷快乐体
  'zcool-wenyiti', // 站酷文艺体
];
function ProjectDetail () {
  const [showSearchMediaModal, setShowSearchMediaModal] = useState(false);
  const [showProduceVideoModal, setShowProduceVideoModal] = useState(false);
  const [tidlist, setTidlist] = useState([]);
  const [tidaudiolist, setTidaudiolist] = useState([]);
  const [captionsdata, setCaptionsdata] = useState([]);
  const [agentclassify, setAgentclassify] = useState([]);
  const [arrnum, setArrnum] = useState(0);
  const [storeid, setStoreid] = useState(0);
  const [agentid, setAgentid] = useState(0);
  const searchMediaRef = useRef({});
  const produceVideoRef = useRef({});
  const [commonItemsdatax, setCommonItemsdatax] = useState([]);
  // const [uploads] = useState(true);
  const params = useParams();
  const { projectId } = params;
  // const transVoiceGroups = (data = []) => {
  //   return data.map(({ Type: type, VoiceList = [] }) => {
  //     return {
  //       type,
  //       voiceList: VoiceList.map((item) => {
  //         const obj = {};
  //         Object.keys(item).forEach((key) => {
  //           // obj[lowerFirst(key)] = item[key];
		// 	obj[key] = item[key];
  //         });
  //         return obj;
  //       }),
  //     };
  //   });
  // };
  // 自定义专属人声开始
  
 //  const customVoiceGroups = requestGet('ListSmartVoiceGroups',{storeid:storeid}).then((res) => {
 //    // const commonItems = transVoiceGroups(get(res, 'data.VoiceGroups', []));
	// const commonItems = transVoiceGroups(res.data.VoiceGroups);
	// console.log(commonItems,"xxxxxxxxxx")
	// // setCommonItemsdatax(commonItems)
	// return  commonItems;
 //  })
  const customVoiceGroups = async () => {
    const res = await request("ListSmartVoiceGroups", {
      ProjectId: projectId,
    });
	console.log(res.data.VoiceGroups,"res.data.VoiceGroupsres.data.VoiceGroups")
    setCommonItemsdatax(res.data.voiceGroups)
  
  };
  // 自定义专属人声结束
  useEffect(() => {
    if (arrnum === 0) {
      projectarr()
      projectstoreid()
      projectagentclassify()
	  customVoiceGroups()
    }
    const myLocale = "zh-CN";
	console.log(commonItemsdatax,"customVoiceGroupscustomVoiceGroupscustomVoiceGroups")
	
    window.AliyunVideoEditor.init({
	  customVoiceGroups:commonItemsdatax,//自定义专属人声
      customTexts: {
        updateButton: "保存草稿",
        produceButton: "提交剪辑",
      },
	  
	  
	  // 自定义字体
	  customFontList: FONT_FAMILIES.concat([
	  	{
	  	  key: 'dad7ba30839971ee80baf6f7d6496304', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '俪黑金', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/ttf/%E4%BF%AA%E9%87%91%E9%BB%91.TTF',
	  	},
	  	// {
	  	//   key: 'RobotoCondensed-Light', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	//   name: 'RobotoCondensed-Light', // 展示在页面的名称
	  	//   // url 是字体文件的地址
	  	//   url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/RobotoCondensed-Light.TTF',
	  	// },
	  	
	  	// {
	  	//   key: '字由点字峰骨', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	//   name: '字由点字峰骨', // 展示在页面的名称
	  	//   // url 是字体文件的地址
	  	//   url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E7%94%B1%E7%82%B9%E5%AD%97%E5%B3%B0%E9%AA%A8.TTF',
	  	// },
	  	{
	  	  key: '字由点字机械体', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '字由点字机械体', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E7%94%B1%E7%82%B9%E5%AD%97%E6%9C%BA%E6%A2%B0%E4%BD%93.TTF',
	  	},
	  	{
	  	  key: '字由点字江户招牌黑', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '字由点字江户招牌黑', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E7%94%B1%E7%82%B9%E5%AD%97%E6%B1%9F%E6%88%B7%E6%8B%9B%E7%89%8C%E9%BB%91.TTF',
	  	},
	  	// {
	  	//   key: '字由点字江湖体', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	//   name: '字由点字江湖体', // 展示在页面的名称
	  	//   // url 是字体文件的地址
	  	//   url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E7%94%B1%E7%82%B9%E5%AD%97%E5%B3%B0%E9%AA%A8.TTF',
	  	// },
	  	{
	  	  key: '字由点字油漆体', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '字由点字油漆体', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E7%94%B1%E7%82%B9%E5%AD%97%E6%B2%B9%E6%BC%86%E4%BD%93.TTF',
	  	},
	  	{
	  	  key: '字由点字烈金体', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '字由点字烈金体', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E7%94%B1%E7%82%B9%E5%AD%97%E7%83%88%E9%87%91%E4%BD%93.TTF',
	  	},
	  	{
	  	  key: '字由点字胖嘟', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '字由点字胖嘟', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E7%94%B1%E7%82%B9%E5%AD%97%E8%83%96%E5%98%9F.TTF',
	  	},
	  	{
	  	  key: '字由点字逸致拼音体', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '字由点字逸致拼音体', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E7%94%B1%E7%82%B9%E5%AD%97%E9%80%B8%E8%87%B4%E6%8B%BC%E9%9F%B3%E4%BD%93.TTF',
	  	},
	  	{
	  	  key: '字由点字黑糖', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '字由点字黑糖', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E7%94%B1%E7%82%B9%E5%AD%97%E9%BB%91%E7%B3%96.TTF',
	  	},
	  	{
	  	  key: '字语飞扬行书', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '字语飞扬行书', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E8%AF%AD%E9%A3%9E%E6%89%AC%E8%A1%8C%E4%B9%A6.ttf',
	  	},
	  	// {
	  	//   key: '字魂138号-霸燃手书', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	//   name: '字魂138号-霸燃手书', // 展示在页面的名称
	  	//   // url 是字体文件的地址
	  	//   url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E9%AD%82138%E5%8F%B7-%E9%9C%B8%E7%87%83%E6%89%8B%E4%B9%A6.TTF',
	  	// },
	  	{
	  	  key: '字魂213号-琉璃宋', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	  name: '字魂213号-琉璃宋', // 展示在页面的名称
	  	  // url 是字体文件的地址
	  	  url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E5%AD%97%E9%AD%82213%E5%8F%B7-%E7%90%89%E7%92%83%E5%AE%8B.ttf',
	  	},
	  	// {
	  	//   key: '汉仪喵魂漫画体 W.', // 需要是唯一的key，不能与与其他字体相同，中英文均可
	  	//   name: '汉仪喵魂漫画体 W.', // 展示在页面的名称
	  	//   // url 是字体文件的地址
	  	//   url: 'https://zmtmuban.oss-cn-shanghai.aliyuncs.com/aijianji/%E6%B1%89%E4%BB%AA%E5%96%B5%E9%AD%82%E6%BC%AB%E7%94%BB%E4%BD%93%20W.TTF',
	  	// }
	  ]),
	 
      container: document.getElementById("container"),
      locale: myLocale,
      // customVoiceGroups: customVoiceGroups,//自定义专属人声
      // defaultSubtitleText: "智媒体SASS",
	  defaultSubtitleText: "可视化批量剪辑",
      useDynamicSrc: true, // 媒资库默认情况下播放地址会过期，所以需要动态获取

      getDynamicSrc: (mediaId, mediaType, mediaOrigin, curSrc)=>{
      // getDynamicSrc: (mediaId, mediaType) => {
      		  if(mediaType==='font'){
      			  return curSrc
      		  }else{
      			return request("GetMediaInfo", {
      			  // https://help.aliyun.com/document_detail/197842.html
				  ProjectId: projectId,
      			  MediaId: mediaId,
      			}).then((res) => {
      			  // 注意，这里仅作为示例，实际中建议做好错误处理，避免如 FileInfoList 为空数组时报错等异常情况
      			  return res.data.mediaInfo.fileInfoList[0].fileBasicInfo.fileUrl;
      			});
      		}
      },
      getEditingProjectMaterials: () => {
        return request("GetEditingProjectMaterials", {
          // https://help.aliyun.com/document_detail/209068.html
          ProjectId: projectId,
        }).then((res) => {
          const data = res.data.mediaInfos;
          return transMediaList(data); // 需要做一些数据变换
        });
      },
      searchMedia: (mediaType) => {
        // uploads();
        // mediaType 为用户当前所在的素材 tab，可能为 video | audio | image，您可以根据这个参数对应地展示同类型的可添加素材
        return new Promise((resolve, reject) => {
          // console.log(searchMediaRef);
          // 调用方需要自己实现展示媒资、选择媒资添加的界面
          // 关于展示媒资，请参考：https://help.aliyun.com/document_detail/197964.html
          searchMediaRef.current = {
            resolve,
            reject,
          };
          setShowSearchMediaModal(true);
        });
      },
      deleteEditingProjectMaterials: async (mediaId, mediaType) => {
        return request("DeleteEditingProjectMaterials", {
          // https://help.aliyun.com/document_detail/209067.html
          ProjectId: projectId,
          MaterialType: mediaType,
          MaterialIds: mediaId,
        });
      },
      getStickerCategories: async () => {
        const res = await request("ListAllPublicMediaTags", {
          // https://help.aliyun.com/document_detail/207796.html
          BusinessType: "sticker",
          WebSdkVersion: window.AliyunVideoEditor.version,
        });

        const stickerCategories = res.data.mediaTagList.map((item) => ({
          id: item.mediaTagId,
          name:
            myLocale === "zh-CN"
              ? item.mediaTagNameChinese
              : item.mediaTagNameEnglish, // myLocale 是您期望的语言
        }));
        return stickerCategories;
      },
      getStickers: async ({ categoryId, page, size }) => {
		 
        const params = {
          PageNo: page,
          PageSize: size,
          IncludeFileBasicInfo: true,
          MediaTagId: categoryId,
        };

        const res = await request("ListPublicMediaBasicInfos", params); // https://help.aliyun.com/document_detail/207797.html

        const fileList = res.data.mediaInfos.map((item,key) => ({
          mediaId: item.mediaId,
          src: key>=30?item.fileInfoList[0].fileBasicInfo.fileUrl:item.mediaBasicInfo.inputURL,
        }));

        return {
          total: res.data.totalCount,
          stickers: fileList,
        };
      },
      getEditingProject: async () => {
        const res = await request("GetEditingProject", {
          // https://help.aliyun.com/document_detail/197837.html
          ProjectId: projectId,
        });
        const timelineString = res.data.project.timeline;
        return {
          projectId,
          timeline: timelineString ? JSON.parse(timelineString) : undefined,
          modifiedTime: res.data.project.modifiedTime,
        };



      },
      updateEditingProject: ({ coverUrl, duration, timeline, isAuto }) => {
        // console.log(coverUrl, duration, timeline, isAuto);
        // updateprojectarr()

        if (isAuto === true) {
          return;
        } else {
          return request("UpdateEditingProject", {
            // https://help.aliyun.com/document_detail/197835.html
            ProjectId: projectId,
            CoverURL: coverUrl,
            Duration: duration,
            // arr:tidlist,
            Timeline: encodeURIComponent(JSON.stringify(timeline)),
          }).then((res) => {

            if (res.data.code === 400) {
              message.success(res.data.msg);
            } else {
              // WebSDK 本身会进行自动保存，isAuto 则是告诉调用方这次保存是否自动保存，调用方可以控制只在手动保存时才展示保存成功的提示
              !isAuto && message.success("保存成功");
            }

          });
        }
      },
      produceEditingProjectVideo: ({
        coverUrl,
        duration = 0,
        aspectRatio,
        timeline,
        recommend,
      }) => {
        return new Promise((resolve, reject) => {
          produceVideoRef.current = {
            aspectRatio,
            recommend,
            timeline,
            resolve,
            reject,
          };
          setShowProduceVideoModal(true);
        });
      },
      submitASRJob: (mediaId, startTime, duration) => {
        //语音转文本
        return request("SubmitASRJob", {
          // https://help.aliyun.com/document_detail/203425.html
          inputFile: mediaId,
          startTime,
          duration,
        }).then(async (res) => {
          const jobId = res.data.jobId;
          const interval = 10000; // 轮询的时间间隔，接入方可以自定义
          const totalTimes = 10; // 轮询次数，接入方可以自定义
          let result = {};
          for (let i = 0; i < totalTimes; i++) {
            await new Promise((resolve) => {
              window.setTimeout(resolve, interval);
            });

            // 获取智能任务结果
            result = request("GetSmartHandleJob", {
              // https://help.aliyun.com/document_detail/203429.html
              JobId: jobId,
            });
            const state = res.data.state;
            console.log(state);
            if (state !== "Creating" && state !== "Executing") break;
          }
          await result.then((res) => {
            result = res;
          });
          if (result.data.state === "Finished") {
            // console.log(result.data.output);
            return JSON.parse(result.data.output);
          }
        });
      },
      // 智能生成配音
      submitAudioProduceJob: async (text, voice, voiceConfig = {}) => {
        //  const storageListReq = await requestGet('GetStorageList')
        //  const tempFileStorageLocation = storageListReq.data.StorageInfoList.find((item) => {
        //    return item.EditingTempFileStorage
        //  });
        //  if (!tempFileStorageLocation) {
        //    throw new Error('未设置临时存储路径');
        //  
        //  const {StorageLocation, Path} = tempFileStorageLocation;
        // 智能生成配音会生成一个音频文件存放到接入方的 OSS 上，这里 bucket, path 和 filename 是一种命名的示例，接入方可以自定义
        const bucket = 'duxiezhijian';
        const path = 'autoProducedAudios/';
        const filename = `${text.slice(0, 10)}${Date.now()}`;

        // 1-提交智能配音任务
        const res1 = await request('SubmitAudioProduceJob', { // https://help.aliyun.com/document_detail/212273.html
		 ProjectId: projectId,
          EditingConfig: encodeURIComponent(
            JSON.stringify({
              voice,
              format: 'mp3',
              ...voiceConfig,
            })
          ),
          InputConfig: text,
          OutputConfig: encodeURIComponent(
            JSON.stringify({
              bucket,
              object: `${path}${filename}`
            })
          )
        });

        if (res1.status !== 200) {
          throw new Error('暂未识别当前文字内容');
        }
        console.log(res1.data, "轮训")

        // 2-智能配音任务是否完成【轮询】
        const jobId = res1.data.jobId;
        const getJobStatus = () => {
          return requestGet('GetSmartHandleJob', { // https://help.aliyun.com/document_detail/203429.html
		   ProjectId: projectId,
            JobId: jobId,
          });
        };
        const shouldContinueGetJobStatus = (res) => {
          if (res.status !== 200 || res.data.state === 'Finished') return false;
          return true;
        };
        const { result: res2 } = await poll(
          getJobStatus,
          shouldContinueGetJobStatus,
          2000,
          20000
        );

        // 3-智能配音任务完成则拉取生成的音频【轮询】
        if (res2.status === 200 && res2.data.state === 'Finished') {
          const mediaId = res2.data.output;

          const getProducedAudioInfo = () => {
            return request('GetMediaInfo', {
			  ProjectId: projectId,
              MediaId: mediaId,
            });
          };
          const shouldContinueGetProducedAudioInfo = (res) => {
            if (res.status !== 200) return false;
            if (res.data?.mediaInfo?.mediaBasicInfo?.status === 'Normal') return false;
            return true;
          };
          const res3 = await poll(
            getProducedAudioInfo,
            shouldContinueGetProducedAudioInfo,
            5000,
            15000,
          );

          if (res3.timeout) {
            throw new Error('智能配音任务超时，请重新发起');
          } else {
            const result = transMediaList([res3.result.data.mediaInfo]); // transMediaList 同前文中的定义
            const newAudio = result[0];
            // 4-将新的音频素材与工程进行绑定
            await request('AddEditingProjectMaterials', {
              ProjectId: projectId,
              MaterialMaps: JSON.stringify({
                audio: newAudio.mediaId,
              }),
            });
            console.log('newAudio', newAudio);
            return newAudio;
          }
        } else {
          throw new Error(res2.data.ErrorMsg || '抱歉，暂未识别当前文字内容');
        }
      },
      // submitAudioProduceJob: (text, voice, voiceConfig = {}) => {
      //   //文本转语音
      //   // 智能生成配音会生成一个音频文件存放到接入方的 OSS 上，这里 bucket, path 和 filename 是一种命名的示例，接入方可以自定义
      //   const bucket = "duxiezhijian";
      //   const path = "autoProducedAudios/";
      //   const filename = `${text.slice(0, 10)}${Date.now()}`;
      //   // 1-提交智能配音任务
      //   return request("SubmitAudioProduceJob", {
      //     // https://help.aliyun.com/document_detail/212273.html
      //     EditingConfig: encodeURIComponent(
      //       JSON.stringify({
      //         voice,
      //         format: "mp3",
      //         ...voiceConfig,
      //       })
      //     ),
      //     InputConfig: text,
      //     OutputConfig: encodeURIComponent(
      //       JSON.stringify({
      //         bucket,
      //         object: `${path}${filename}`,
      //       })
      //     ),
      //   }).then(async (res1) => {
      //     const interval = 5000; // 轮询的时间间隔，接入方可以自定义
      //     const totalTimes = 10; // 轮询次数，接入方可以自定义
      //     for (let i = 0; i < totalTimes; i++) {
      //       await new Promise((resolve) => {
      //         window.setTimeout(resolve, interval);
      //       });
      //       // 2-智能配音任务是否完成【轮询】
      //       const jobId = res1.data.jobId;
      //       const getJobStatus = () => {
      //         return request("GetSmartHandleJob", {
      //           // https://help.aliyun.com/document_detail/203429.html
      //           JobId: jobId,
      //         });
      //       };
      //       const shouldContinueGetJobStatus = (res) => {
      //         if (res.code !== "200" || res.data.State === "Finished")
      //           return false;
      //         return true;
      //       };

      //       const { result: res2 } = await poll(
      //         getJobStatus,
      //         shouldContinueGetJobStatus,
      //         2000,
      //         20000
      //       );
      //       if (res2.data.state === "Finished") {
      //         const mediaId = res2.data.output;
      //         console.log(res2);
      //         const getProducedAudioInfo = () => {
      //           return request("GetMediaInfo", {
      //             MediaId: mediaId,
      //           }).then((ress) => {
      //             console.log(ress);
      //           });
      //         };

      //         const shouldContinueGetProducedAudioInfo = (res) => {
      //           if (res.code !== "200") return false;

      //           if (res.data?.MediaInfo?.MediaBasicInfo?.Status === "Normal")
      //             return false;
      //           return true;
      //         };

      //         const res3 = await poll(
      //           getProducedAudioInfo,
      //           shouldContinueGetProducedAudioInfo,
      //           5000,
      //           15000
      //         );

      //         if (res3.timeout) {
      //           throw new Error("智能配音任务超时，请重新发起");
      //         } else {
      //           const result = await transMediaList([
      //             res3.result.data.mediaInfo,
      //           ]); // transMediaList 同前文中的定义
      //           const newAudio = result[0];

      //           await request("AddEditingProjectMaterials", {
      //             ProjectId: projectId,
      //             MaterialMaps: encodeURIComponent(
      //               JSON.stringify({
      //                 audio: mediaId,
      //               })
      //             ),
      //           });
      //           return newAudio;
      //         }
      //       }
      //     }
      //   });
      // },
    });
    // function uplaods(e){
    //   console.log(e);
    // }

    return () => {
      window.AliyunVideoEditor.destroy();
    };
  // }, [projectId]);// eslint-disable-line
    }, [projectId,commonItemsdatax]);// eslint-disable-line
  // const updateprojectarr = async() => {
  //   console.log(tidlist,"tidlisttidlist")
  // 	 //  const res = await request("UpdateEditingProjectarr", {
  // 		// ProjectId: projectId,
  // 		// arr:tidlist,
  // 	 //  });
  //   };
  const projectarr = async () => {
    setArrnum(1)
    const res = await request("GetEditingProjectarr", {
      ProjectId: projectId,
    });
    var arr = Object.entries(res.data).map(([key, value]) => ({
      key, value
    }))
    var arrdata = [];
    for (var i = 0; i < arr.length; i++) {
      arrdata[arr[i]['key']] = arr[i]['value']
    }
    // setTidlist(arrdata)
    console.log(arrdata, "arrdata")
    setTidlist(arrdata)
    //花字
    const reshz = await request("GetEditingProjectarrhz", {
      ProjectId: projectId,
    });
    var arrhz = Object.entries(reshz.data).map(([key, value]) => ({
      key, value
    }))

    var arrdatahz = [];
    for (var ik = 0; ik < arrhz.length; ik++) {
      arrdatahz[arrhz[ik]['key']] = arrhz[ik]['value']
    }
    setCaptionsdata(arrdatahz)
    const resaudio = await request("GetEditingProjectarraudio", {
      ProjectId: projectId,
    });
    var arraudio = Object.entries(resaudio.data).map(([key, value]) => ({
      key, value
    }))

    var arrdataaudio = [];
    for (var ia = 0; i < arraudio.length; i++) {
      arrdataaudio[arraudio[ia]['key']] = arraudio[ia]['value']
    }
    // setTidlist(arrdata)
    console.log(arrdataaudio, "arrdataaudio")
    setTidaudiolist(arrdataaudio)

  };
  const projectstoreid = async () => {
    const res = await request("GetEditingProjectstoreid", {
      ProjectId: projectId,
    });
    setStoreid(res.data.store_id)
    setAgentid(res.data.agent_id)

  };
  const projectagentclassify = async () => {
    const res = await request("GetEditingProjectagentclassify", {
      ProjectId: projectId,
    });
    setAgentclassify(res.data)
  };

  const changeModelShow = () => {

  }
  const resourceOpen = (info) => {
    // 打开/关闭弹框
    setShowSearchMediaModal(false)
    searchMediaRef.current.resolve(info);
    message.success("导入成功")
  }
  const resourceClose = () => {
    console.log('resourceClose');
    setShowSearchMediaModal(false)
  }

  return (
    <div>
      <div id="container" style={{ height: "100vh" }} />
      <ItemvideoModal
        tidaudiolist={tidaudiolist}
        setTidaudiolist={setTidaudiolist}
        tidlist={tidlist}
        setTidlist={setTidlist}
        captionsdata={captionsdata}
        setCaptionsdata={setCaptionsdata}
        storeid={storeid}
        agentid={agentid}
      />
      {/* {showSearchMediaModal && (
        <SearchMediaModal
          onSubmit={(info) => {
            setShowSearchMediaModal(false);
            searchMediaRef.current.resolve(info);
          }}
          onClose={() => {
            setShowSearchMediaModal(false);
            searchMediaRef.current.reject();
          }}
          projectId={projectId}
          storeid={storeid}
          agentid={agentid}
        />
        )} */}
      {showSearchMediaModal && (
        <ResourceManagement
          open={showSearchMediaModal}
          onOk={resourceOpen}
          onClone={resourceClose}
          projectId={projectId}
          storeid={storeid}
          agentid={agentid}
        />
      )
      }

      {showProduceVideoModal && (
        <ProduceVideoModal
          aspectRatio={produceVideoRef.current.aspectRatio}
          agentclassify={agentclassify}
          recommend={produceVideoRef.current.recommend}
          storeid={storeid}
          agentid={agentid}
          onSubmit={async ({
            fileName,
            // format,
            // bitrate,
            // resolution,
            // ossBucket,
            ossnum,
            classifyid,
          }) => {
            // 假设提交合成任务的界面让你获得了这些数据
            // 先根据 fileName 和 format 拼接出存储的 mediaURL
            // const mediaURL = `${ossBucket}${fileName}.${format}`;
            const ossnumdata = `${ossnum}`;
            const title = `${fileName}`;
            const classifyiddata = `${classifyid}`;
            // const [width, height] = resolution;
            // console.log(produceVideoRef.current.timeline,'++++++++++++++');
            // return;

            try {
              const dataSubmitMediaProducingJob = await request("SubmitMediaProducingJob", {
                // https://help.aliyun.com/document_detail/197853.html

                OutputMediaConfig: encodeURIComponent(
                  JSON.stringify({
                    // mediaURL,
                    // bitrate,
                    // width,
                    // height,
                  })
                ),
                OutputMediaTarget: "oss-object",
                ProjectId: projectId,
                Timeline: encodeURIComponent(
                  JSON.stringify(produceVideoRef.current.timeline)
                ),
                ossnum: ossnumdata,
                title: title,
                classifyiddata: classifyiddata,
              });
              if (dataSubmitMediaProducingJob.data.code === 1) {
                message.success("生成视频成功");
              }
              else {
                message.error(dataSubmitMediaProducingJob.data.msg);
              }
            } catch (error) {
              message.success(error);
            }

            setShowProduceVideoModal(false);
            produceVideoRef.current.resolve();
          }}
          onClose={() => {
            setShowProduceVideoModal(false);
            produceVideoRef.current.reject();
          }}
        />
      )}
    </div>
  );
}

export default ProjectDetail;

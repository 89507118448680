
import { PlusCircleFilled, PlusOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { Button, Modal } from "antd";
import $ from 'jquery';
import { useEffect, useState, Children } from 'react';
import { request, transMediaList, poll } from "./utils";
import { useParams } from "react-router";
// import type { CheckboxChangeEvent } from 'antd/es/checkbox';
// import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Player } from "video-react";
import MediaItem from "./MediaItem";
import VideoejectModal from "./components/VideoejectModal/VideoejectModal";//资源库
import StickerModal from "./StickerModal";//贴纸
import SpecialeffectsModal from "./SpecialeffectsModal";//特效
import TransitionModal from "./TransitionModal";//转场
import FilterModal from "./FilterModal";//滤镜
import CaptionsModal from "./CaptionsModal";//字幕


function ItemvideoModal (props) {
	const { setTidlist, tidlist, setTidaudiolist, tidaudiolist, captionsdata, setCaptionsdata, storeid, agentid } = props

	const [showSearchMediaModal, setShowSearchMediaModal] = useState(false);
	const [stickershowSearchMediaModal, setStickershowSearchMediaModal] = useState(false);
	const [specialeffectsMediaModal, setSpecialeffectsMediaModal] = useState(false);
	const [transitionModal, setTransitionModal] = useState(false);
	const [hzid, setHzid] = useState([]);
	const [videooption, setVideooption] = useState([]);

	const [filterModal, setFilterModal] = useState(false);
	const [captionsModal, setCaptionsModal] = useState(false);
	const [tid, setTid] = useState('');

	const [totiddata, setTotiddata] = useState([]);
	const [listnum, setListnum] = useState(0);
	// const [ejecttype, setEjecttype] = useState("");
	const [ymlistnum, setYmListnum] = useState(0);
	// const [arrnum, setArrnum] = useState(0);
	const [typenum, setTypenum] = useState(2);
	const params = useParams();
	const { projectId } = params;
	setTimeout(() => {
		// setListnum($("div.TrackContainer_material_block__1K0T1").length);
		if ($("div.TrackContainer_material_block__1K0T1").length > 0) {
			setListnum($("div.TrackContainer_material_block__1K0T1").length);
		} else {
			setListnum($("div.TrackContainer_trackContainer__3rfs4").length);
		}
		setYmListnum($("button.button_basic_btn__2RRYm").length);
		// $("button.button_basic_btn__2RRYm").click(function(){
		// 	console.log("updateprojectarr")
		//     updateprojectarr()
		// });
		// 创建一个观察器实例并传入回调函数
	}, 2000)
	useEffect(() => {
		if (listnum > 0) {
			changes()
			watchDom("TrackContainer_singleTrack__j7wEH")
			watchDom("TrackContainer_trackContainer__3rfs4")
		}
		$("button.button_basic_btn__2RRYm").click(function () {
			console.log("updateprojectarr")
			updateprojectarr()
		});
		// if(arrnum===0){
		// 	projectarr()
		// }

	}, [listnum]);// eslint-disable-line

	const watchDom = (targetClass) => {
		//获取DOM节点
		const container = document.getElementsByClassName(targetClass)
		//配置检测哪些变化可以被observe检测到
		const options = {
			childList: true,//检测目标节点的删除与新增
		}
		// 创建MutationObserver实例，返回一个观察者对象
		const mutation = new MutationObserver(function (mutationRecoards, observer) {
			changes()
		})
		// 对观察者添加需要观察的元素，并设置需要观察元素的哪些方面
		const num = container.length
		for (var i = 0; i < num; i++) {
			mutation.observe(container[i], options);
		}
	};
	const updateprojectarr = async () => {
		const res = await request("UpdateEditingProjectarr", {
			ProjectId: projectId,
			arr: tidlist,
			arrhz: captionsdata,
			arraudio: tidaudiolist,
		});
	};
	// const projectarr = async() => {
	// 	setArrnum(1)
	// 	  const res = await request("GetEditingProjectarr", {
	// 		ProjectId: projectId,
	// 	  });
	// 	  var arr=Object.entries(res.data).map(([key, value]) => ({
	// 	      key, value
	// 	  }))
	// 	  var arrdata=[];
	// 	  for(var i=0;i<arr.length;i++){
	// 		  arrdata[arr[i]['key']]=arr[i]['value']
	// 	  }
	// 	  // setTidlist(arrdata)
	// 	  console.log(arrdata,"arrdata")
	// 	   setTidlist(arrdata)
	//   };
	const changes = () => {
		var list = $("div.TrackContainer_material_block__1K0T1");
		for (var i = 0; i < list.length; i++) {
			if ($(".wyjia" + list.eq(i).attr("data-id")).length < 1) {
				$("div.TrackContainer_material_block__1K0T1[data-id='" + list.eq(i).attr("data-id") + "']").append("<div class='wyjia" + list.eq(i).attr("data-id") + " wyjia' data-id='" + list.eq(i).attr("data-id") + "'>" + $("#fx").html() + "</div>")

				$("div.TrackContainer_material_block__1K0T1[data-id='" + list.eq(i).attr("data-id") + "']").find(".TrackContainer_transition_block__1OxdA").click(function (data) {

					showModal($(this).parent().attr("data-id"), 8)
				})
				$(".wyjia" + list.eq(i).attr("data-id")).click(function (data) {
					var res = $(this).parent().find('.TrackContainer_video_block__3R14A').length
					if (res) {
						// setEjecttype("video")
						showModal($(this).attr("data-id"), 1, 'video')
					}
					res = $(this).parent().find('.TrackContainer_image_block__TiUd1').length

					if (res) {
						// setEjecttype("image")
						showModal($(this).attr("data-id"), 1, 'image')
					}
					res = $(this).parent().find('.TrackContainer_text_block__3J4zd').length
					if (res) {
						showModal($(this).attr("data-id"), 2)
					}
					res = $(this).parent().find('.TrackContainer_sticker_block__z7WNI').length
					if (res) {
						showModal($(this).attr("data-id"), 3)
					}
					res = $(this).parent().find('.TrackContainer_filter_block__34dCx').length
					if (res) {
						showModal($(this).attr("data-id"), 4)
					}
					res = $(this).parent().find('.TrackContainer_effect_block__3ZYLL').length
					if (res) {
						showModal($(this).attr("data-id"), 6)
					}
					res = $(this).parent().find('.TrackContainer_audio_block__1lIM1').length
					if (res) {
						showModal($(this).attr("data-id"), 7)
					}
				});
			}
		}


	};
	const showModal = (data, type, ejecttype) => {
		setTid(data)
		if (!tidlist[data]) {
			setTotiddata([])

		} else {
			setTotiddata(tidlist[data])
		}
		if (type === 1) {//资源库
			if (ejecttype === 'video') {
				setVideooption([
					// { label: "全部", value: "all" },
					{ label: "视频", value: "video" },
					// { label: "音频", value: "audio" },
					// { label: "图片", value: "image" },
				])
			}
			if (ejecttype === 'image') {
				setVideooption([
					// { label: "全部", value: "all" },
					// { label: "视频", value: "video" },
					// { label: "音频", value: "audio" },
					{ label: "图片", value: "image" },
				])
			}

			setShowSearchMediaModal(true);
		}
		if (type === 2) {//字幕

			setCaptionsModal(true);
		}
		if (type === 3) {//贴纸
			setStickershowSearchMediaModal(true);
		}
		if (type === 4) {//滤镜
			setFilterModal(true);
		}
		if (type === 6) {//特效
			setSpecialeffectsMediaModal(true);
		}
		if (type === 7) {//音频
			setVideooption([
				// { label: "全部", value: "all" },
				// { label: "视频", value: "video" },
				{ label: "音频", value: "audio" },
				// { label: "图片", value: "image" },
			])
			setShowSearchMediaModal(true);
		}
		if (type === 8) {//转场
			setTransitionModal(true);
		}
	};
	const onClick = (value) => {
		var tidlistx = tidlist
		console.log(value, "valuevaluevvaluevaluevalue")
		value.forEach(function (item, key, array) {
			tidlistx[key] = item
		});
		setTidlist(tidlistx)
	}

	const onClickhzparent = (value) => {
		var data = captionsdata
		if (!data[tid]) {
			data[tid] = []

		}
		data[tid][hzid] = [];
		data[tid][hzid] = value
		setCaptionsdata(data)
	}

	return (
		<div >
			{captionsModal}
			<div id="fx" style={{ display: "none" }} >
			<PlusOutlined style={{ color: "#fff", fontSize: "16px", position: "absolute", right: "10px", bottom: "2px",backgroundColor: '#1378f9', borderRadius: '50%', padding: '2px' }}  />
			</div>
			{/* 选择资源 */}
			{showSearchMediaModal && (
				<VideoejectModal
					onSubmit={(info) => {
						console.log("关闭")
						setShowSearchMediaModal(false);
					}}
					onClose={() => {
						setShowSearchMediaModal(false);
					}}
					click={onClick}
					tid={tid}
					storeid={storeid}
					agentid={agentid}
					tiddata={totiddata}
					projectId={projectId}
					options={videooption}
					setTidaudiolist={setTidaudiolist}
					tidaudiolist={tidaudiolist}
				/>
			)}
			{stickershowSearchMediaModal && (
				<StickerModal
					onSubmit={(info) => {
						console.log("关闭")
						setStickershowSearchMediaModal(false);

					}}
					onClose={() => {
						setStickershowSearchMediaModal(false);

					}}
					click={onClick}
					tid={tid}
					tiddata={totiddata}
					projectId={projectId}
				/>
			)}
			{specialeffectsMediaModal && (
				<SpecialeffectsModal
					onSubmit={(info) => {
						console.log("关闭")
						setSpecialeffectsMediaModal(false);

					}}
					onClose={() => {
						setSpecialeffectsMediaModal(false);

					}}
					click={onClick}
					tid={tid}
					tiddata={totiddata}
					projectId={projectId}
				/>
			)}
			{transitionModal && (
				<TransitionModal
					onSubmit={(info) => {
						console.log("关闭")
						setTransitionModal(false);

					}}
					onClose={() => {
						setTransitionModal(false);

					}}
					click={onClick}
					tid={tid}
					tiddata={totiddata}
					projectId={projectId}
				/>
			)}
			{filterModal && (
				<FilterModal
					onSubmit={(info) => {
						console.log("关闭")
						setFilterModal(false);

					}}
					onClose={() => {
						setFilterModal(false);

					}}
					click={onClick}
					tid={tid}
					tiddata={totiddata}
					projectId={projectId}
				/>
			)}
			{captionsModal && (
				<CaptionsModal
					onSubmit={(info) => {
						console.log("关闭")
						setCaptionsModal(false);

					}}
					onClose={() => {
						setCaptionsModal(false);

					}}
					click={onClick}
					tid={tid}
					tiddata={totiddata}
					captionsdata={captionsdata}

					projectId={projectId}
					onClickhzparent={onClickhzparent}
					setHzid={setHzid}
					hzid={hzid}
				/>
			)}

		</div>
	);
};
export default ItemvideoModal;
import { formatTime } from './utils'

function MediaItemcopywriting (props) {
  const { item, onClick, selected } = props

  let coverUrl
  let title
  let duration = 0;
	coverUrl = item.coverUrl
	title = item.title
	
  const style = {
	 border:"2px solid #ccc",
	 padding:"10px",
  }	

  return (
    <div className={`itemx ${selected ? 'selected' : ''}`} onClick={onClick}>
      <div  style={style} >
	  <p>问题：<span>{item.problems}</span></p>
	  <p>答案：<span>{item.answer}</span></p>
	  </div>
	  
    
      
    </div>
  )
}

export default MediaItemcopywriting

import styled from "styled-components";
export const ResourceManagementWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    /* height: 700px; */
    background-color: #090a0c;
    display: none;
    z-index: 99;

    .head_wrapper {
        color: #fff;
        width: 100%;
        height: 40px;
        background-color: #090a0c;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
    }

    .content_box {
        width: 100%;
        /* height: 100%; */
        padding: 16px;
        background-color: #25272b;
        .main_body {
            width: 100%;
            height: 100%;
            background-color: #171a1e;
            display: flex;
            .left_cont {
                width: 250px;
                padding: 14px 20px;
                height: 540px;
                overflow-y: scroll;
                scrollbar-width: none; /* firefox */
                -ms-overflow-style: none; /* IE 10+ */
                .classify_box {
                    width: 100%;
                    height: 40px;
                    padding: 0 16px;
                    border-radius: 4px;
                    line-height: 40px;
                    white-space:nowrap;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    margin-bottom: 12px;
                    color: #ccc;
                    background-color: rgba(0,0,0,0.2);
                    position: relative;
                    &:hover {
                        .deleteFilled {
                            display: block;
                        }
                    }
                    .deleteFilled {
                        position: absolute;
                        top: 12px;
                        right: 10px;
                        color: red;
                        display: none;
                    }
                }
                .folder_add_btn {
                    background-color: #18bc9c;
                    color: #fff;
                }
                .class_active {
                    color: #fff;
                    background-color: #000;
                }
            }
            .left_cont::-webkit-scrollbar {
                display: none; /* Chrome Safari */
            }
            .right_cont {
                padding: 14px 20px;
                flex: 1;
                .ceiling_button {
                    display: flex;
                }
                .ceiling_button > div,
                .ceiling_button > button {
                    margin-right: 10px;
                }
                .tabs {
                    display: flex;
                    color: #fff;
                    .tabs_item {
                        margin-right: 10px;
                    }
                }
                .media_content {
                    position: relative;
                    min-height: 380px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    padding: 16px 0;
                    .media_item{
                        padding: 4px 4px 0 4px;
                        width: 138px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        color: #fff;
                        &:nth-child(6n) {
                            margin-right: 0;
                        }
                        .img_video {
                            width: 100%;
                            height: 120px;
                            position: relative;
                            background-repeat: no-repeat;
                            /* background-size: cover; */
                            background-size: contain;
                            background-position: center;
                            > img {
                                width: 100%;
                                height: 100%;
                                object-fit: fill;
                            }
                            .duration {
                                padding: 2px 4px;
                                border-radius: 4px;
                            }
                        }
                        .title {
                            margin: 8px 0;
                            width: 100%;
                            white-space:nowrap;
                            overflow:hidden;
                            text-overflow:ellipsis;
                        }
                        .media_check {
                            height: 20px;
                            width: 48px;
                            /* opacity: .8; */
                            background-color: #1378f9;
                            box-shadow: 0 0 4px #0009;
                            border-radius: 10px;
                            text-align: center;
                            line-height: 20px;
                            font-size: 12px;
                            position: absolute;
                            top: 6px;
                            left: 8px;
                        }
                    }
                }
                .page_break{
                    display: flex;
                    justify-content: flex-end;
                    /* 修改 antd 样式 */
                    /* .ant-pagination-item-ellipsis,
                    .ant-pagination-options-quick-jumper {
                        color: #fff;
                    } */
                }
            }
        }
    }

    .bottom_box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 100%;
        /* position: absolute;
        bottom: 0;
        left: 0; */
        background: #090a0c;
    }

    .folder_add {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #090a0c;
        /* width: 200px; */
        padding: 16px;
        border-radius: 4px;
        z-index: 99;
    }
    
`
export const UploadStyle = styled.div`
    .ant-upload-list {
        max-height: 400px;
        overflow-y: scroll;
    }
`
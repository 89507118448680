import axios from 'axios';
const baseURL = 'https://yunhong.yinwukeji.com/api'

/**
 * 添加分类
 */
export const categoryAddAPI = (data) => axios.post(baseURL + '/pc/buiattach/category_add?_ajax=1', data);
/**
 * 删除素材
 * @param {ids: string[]} data 
 * @returns 
 */
export const buiattachDelAPI = (data) => axios.post(baseURL + '/pc/buiattach/del?_ajax=1', data)
/**
 * 移动分类 
 * @param {category: 分类id,ids:string[]} data 
 * @returns 
 */
export const moveClassifyAPI = (data) => axios.post(baseURL + '/pc/buiattach/classify?_ajax=1', data)
/**
 * 分类列表
 * @param {storeid,agentid} data 
 * @returns 
 */
export const getCategoryListAPI = (data) => axios.post(baseURL + '/pc/buiattach/categoryList?_ajax=1', data)
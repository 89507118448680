import { useEffect, useState } from "react";
import { Modal, Pagination, Radio } from 'antd'
import { request, transMediaList, requeststoreadmin } from "./utils";
import MediaItem from "./MediaItem";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Upload, Tabs } from "antd";
import axios from "axios";
// eslint-disable-next-line
const options = [
  { label: "全部", value: "all" },
  { label: "视频", value: "video" },
  { label: "音频", value: "audio" },
  { label: "图片", value: "image" },
];
const items = [
  {
    key: '1',
    label: `选择`,
    children: ``,
  },
  {
    key: '2',
    label: `上传`,
    children: ``,
  }

];

const PAGE_SIZE = 20;

function SearchMediaModal (props) {
  const { onSubmit, onClose, projectId, storeid, agentid } = props;
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [tabstatus, setTabstatus] = useState('one');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [mediaType, setMediaType] = useState(options[0].value);
  const [status, setStatus] = useState("loading");
  // 定义了page变量，默认为1，可以使用setPage进行改变page的值
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [media, setMedia] = useState([]);
  const [storeagentlist, setStoreagentlist] = useState([]);
  const [fileList, setfileList] = useState([]);
  // const [nextToken, setNextToken] = '';
  useEffect(() => {
    setStatus("loading");
    var datastoreagentlist = [];
    datastoreagentlist['storeid'] = storeid
    datastoreagentlist['agentid'] = agentid
    setStoreagentlist(datastoreagentlist)
    axios.post("https://yunhong.yinwukeji.com/storeadmin/general/attachment/selectaliy", {
      limit: PAGE_SIZE,
      offset: page,
      mimetype: mediaType,
      order: "desc",
      storeid: storeid,
      agentid: agentid,
      filter: encodeURIComponent(JSON.stringify({ "mimetype": mediaType }, "string")),
      op: encodeURIComponent(JSON.stringify({ "mimetype": "LIKE" }, "string"))
    }, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
      }
    }).then((res) => {
      setStatus("done");
      setMedia(res.data.mediaInfos);
      setTotal(res.data.totalCount);
    });
  }, [mediaType, page, storeid, agentid]);

  const handleSubmit = async () => {
    setConfirmLoading(true);
    // 组装数据
    const valueObj = {};
    console.log(selectedMedia, "selectedMediaselectedMediaselectedMedia")
    selectedMedia.forEach((item) => {
      console.log(item, "item,xxxx");
      var mediaTypebf = ""
      if (item.mediaBasicInfo) {
        mediaTypebf = item.mediaBasicInfo.mediaType;
      } else {
        mediaTypebf = item.mediaType;
      }

      // const mediaType = item.mediaType;
      const mediaId = item.mediaId;
      if (!valueObj[mediaTypebf]) {
        valueObj[mediaTypebf] = mediaId;
      } else {
        valueObj[mediaTypebf] += `,${mediaId}`;
      }
    });
    console.log(valueObj, "valueObjvalueObjvalueObj");

    const res = await request("AddEditingProjectMaterials", {
      // https://help.aliyun.com/document_detail/209069.html
      ProjectId: projectId,
      MaterialMaps: encodeURIComponent(JSON.stringify(valueObj, "string")),
    });
    setConfirmLoading(false);
    onSubmit(transMediaList(res.data.mediaInfos));
  };
  const onTabChange = (key) => {
    if (key === "1") {
      setTabstatus('one')

    } else {
      setTabstatus('two')
    }

  };
  const handleMediaTypeChange = (e) => {
    console.log(e);
    setMediaType(e.target.value);
    setPage(1);
  };

  const handleClick = (item) => {
    console.log(item, "handleClick-item");
    const index = selectedMedia.findIndex((m) => m.mediaId === item.mediaId);
    console.log(index);
    if (index > -1) {
      setSelectedMedia(selectedMedia.filter((_, i) => i !== index));
    } else {
      setSelectedMedia([...selectedMedia, item]);
      // console.log([...selectedMedia, item]);
    }
  };
  const requests = (items) => {
    request("GetMediaInfo", {
      mediaId: items,
    });
  };
  // 本地上传素材
  const uploads = (_item) => {
    console.log(_item);
    // console.log(_item.fileList);
    let arrs = _item.fileList;
    for (let i = 0; i < arrs.length; i++) {
      if (arrs[i].type === "image/jpeg" || arrs[i].type === "image/jpg" || arrs[i].type === "image/png") {
        // console.log(arrs[i]);
        let img = [];
        if (arrs[i].status !== "uploading") {
          img.push(arrs[i]);
        }
        // console.log(img);
        if (img.length > 0) {
          for (let j = 0; j < img.length; j++) {
            // const element = array[j];
            if (img[j].response.msg === "上传成功") {
              // console.log(item);
              // return;
              let urls = img[j].response.data.url;
              let type = "image";
              const resimg = axios
                .post(
                  "https://yunhong.yinwukeji.com/agentadmin/Aliyun/registerMediaInfo",
                  { url: urls, type: type }
                )
                .then((res) => {
                  // mediaIds = res.data.data.mediaId;
                  // console.log(mediaIds);
                  request("GetMediaInfo", {
                    MediaId: res.data.data.mediaId,
                  }).then((ress) => {
                    let medias = {
                      mediaType: "image",
                      mediaId: res.data.data.mediaId,
                      video: ress.data.mediaInfo,
                    };
                    // console.log(medias);
                    handleClick(medias);
                  });
                  // console.log(res);
                });
            }
          }
        }
      } else if (arrs[i].type === "video/mp4") {
        let video = [];
        // video.push(arrs[i]);
        // console.log(video);
        if (arrs[i].status !== "uploading") {
          video.push(arrs[i]);
        }
        console.log(video);
        if (video.length > 0) {
          for (let j = 0; j < video.length; j++) {
            // const element = array[j];
            if (video[j].response.msg === "上传成功") {
              // console.log(item);
              // return;
              let urls = video[j].response.data.url;
              let type = "video";
              const resimg = axios
                .post(
                  "https://yunhong.yinwukeji.com/agentadmin/Aliyun/registerMediaInfo",
                  { url: urls, type: type }
                )
                .then((res) => {
                  console.log(res);
                  request("GetMediaInfo", {
                    MediaId: res.data.data.mediaId,
                  }).then((ress) => {
                    // let medias = ress.data.mediaInfo;
                    // console.log(medias);
                    let medias = {
                      mediaType: "video",
                      mediaId: res.data.data.mediaId,
                      video: ress.data.mediaInfo,
                    };
                    handleClick(medias);
                  });
                });
            }
          }
        }
      } else if (arrs[i].type === "audio/mpeg") {
        let audio = [];
        // audio.push(arrs[i]);
        // console.log(audio);
        if (arrs[i].status !== "uploading") {
          audio.push(arrs[i]);
        }
        // console.log(audio);
        if (audio.length > 0) {
          for (let j = 0; j < audio.length; j++) {
            // const element = array[j];
            if (audio[j].response.msg === "上传成功") {
              // console.log(item);
              // return;
              let urls = audio[j].response.data.url;
              let type = "audio";
              const resimg = axios
                .post(
                  "https://yunhong.yinwukeji.com/agentadmin/Aliyun/registerMediaInfo",
                  { url: urls, type: type }
                )
                .then((res) => {
                  request("GetMediaInfo", {
                    MediaId: res.data.data.mediaId,
                  }).then((ress) => {
                    // let medias = ress.data.mediaInfo;
                    // console.log(medias);
                    let medias = {
                      mediaType: "audio",
                      mediaId: res.data.data.mediaId,
                      video: ress.data.mediaInfo,
                    };
                    handleClick(medias);
                  });
                  // handleClick(medias);
                  // console.log(res);
                });
            }
          }
        }
      }
    }
    // arrs.forEach((item,index)=>{
    //   console.log(item);
    //   if (item.type === 'image/jpeg' || item.type === 'image/jpg') {
    //     img.push(item);
    //     console.log(img);
    //     setfileList(img);
    //   }
    // console.log(fileList);
    // })
    // request(())
  };
  const selectedMediaIds = selectedMedia.map((m) => m.mediaId);

  return (
    <Modal
      open
      title="选择媒资导入"
      onOk={handleSubmit}
      onCancel={onClose}
      width={720}
      // okButtonProps={{ disabled: selectedMedia.length === 0 }}
      okText="导入"
      cancelText="取消"
      confirmLoading={confirmLoading}
    >
      <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
      {tabstatus === "two" &&
        <Space
          direction="vertical"
          style={{
            width: "100%",
            marginBottom: "5%",
          }}
          size="large"
        >
          <Upload
            action="https://yunhong.yinwukeji.com/agentadmin/File/upload_ai"
            data={storeagentlist}
            listType="picture"
            accept="image/png,image/jpeg,audio/mpeg,audio/mp3,audio/wav,video/mp4"
            thumbUrl="https://img0.baidu.com/it/u=741268616,1401664941&fm=253&fmt=auto&app=138&f=JPEG?w=748&h=500"
            multiple
          >
            <Button icon={<UploadOutlined />}>本地上传素材</Button>
          </Upload>
        </Space>
      }
      {tabstatus === "one" &&
        <Radio.Group
          style={{ marginBottom: "20px" }}
          options={options}
          onChange={handleMediaTypeChange}
          value={mediaType}
          optionType="button"
          buttonStyle="solid"
        />
      }
      {status === "done" && tabstatus === "one" &&
        (media.length ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {media.map((item) => (
              <MediaItem
                onClick={() => handleClick(item)}
                selected={selectedMediaIds.indexOf(item.mediaId) > -1}
                key={item.mediaId}
                item={item}
                parentmediaType="all"
              />
            ))}
          </div>
        ) : (
          <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
        ))}
      {status === "loading" && tabstatus === "one" && (
        <div style={{ height: "615px", textAlign: "center" }}>加载中...</div>
      )}
      {status === "error" && tabstatus === "one" && (
        <div style={{ color: "red", height: "615px", textAlign: "center" }}>
          加载出错
        </div>
      )}
      {tabstatus === "one" &&
        <Pagination
          style={{ textAlign: "center" }}
          defaultPageSize={PAGE_SIZE}
          current={page}
          total={total}
          showSizeChanger={false}
          onChange={(p) => setPage(p)}
        />
      }

    </Modal>

  );
}

export default SearchMediaModal;

import { memo, useEffect,useRef, useState } from 'react'
import { Col, InputNumber, Row, Slider, Button } from 'antd';
import { GenerateWrapper } from './style'
import { tongyong, kefu, tonsheng, yingwen, fangyan, zhibo } from '../../services'
import { request, transMediaList, poll,requestGet  } from "../../utils";
import { useParams } from "react-router";

const GenerateSpeech = ({changeShow, searchValue}) => {
  const params = useParams();
  const { projectId } = params;
  const tables = [ '通用', '客服', '童声', '英文', '方言', '直播']
  const [tabsActive, setTabsActive] = useState(0)
  const [audioList, setAudioList] = useState([])
  const [audioActive, setAudioActive] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false);
  const myAudioRef = useRef(null);
  const [auditionActive, setAuditionActive] = useState(null)
  function changeAudioList (item, index) {
    setAudioActive(index)
    console.log('changeAudioList', item, index);
  }
  function changeTabs(index) {
    setTabsActive(index)
    setAudioActive(null)
    switch (index) {
      case 0:
        setAudioList(tongyong)
        break;
      case 1:
        setAudioList(kefu)
        break;
      case 2:
        setAudioList(tonsheng)
        break;
      case 3:
        setAudioList(yingwen)
        break;
      case 4:
        setAudioList(fangyan)
        break;
      case 5:
        setAudioList(zhibo)
        break;
      default:
        break;
    }
  }
  
  useEffect(() => {
    console.log('searchValue', searchValue);
    console.log('projectid', projectId);
    setAudioList(tongyong)
    return () => {
      console.log('销毁');
    }
    // eslint-disable-next-line
  }, [projectId])
  
  const [volumeValue, setVolumeValue] = useState(50); // 音量
  const volumeChange = (newValue) => {
    setVolumeValue(newValue);
  };
  const [speechRate, setSpeechRate] = useState(1); // 语速
  const speechRateChange = (newValue) => {
    setSpeechRate(newValue);
  }; 
  const [intonation, setIntonation] = useState(1); // 语调
  const intonationChange = (newValue) => {
    setIntonation(newValue);
  };
  const [loadings, setLoadings] = useState(false);
   const submitAudioProduceJob = async () => {
    setLoadings(true)
    const text = searchValue
    const voice = 'xiaoyun'
    const voiceConfig = {
      pitch_rate: intonation,
      speech_rate: speechRate,
      volume: volumeValue
    }
    const bucket = 'duxiezhijian';
    const path = 'autoProducedAudios/';
    const filename = `${ text.slice(0, 10) }${ Date.now() }`;
    const res1 = await request('SubmitAudioProduceJob', { // https://help.aliyun.com/document_detail/212273.html
      EditingConfig: encodeURIComponent(
      JSON.stringify({
          voice,
          format: 'mp3',
          ...voiceConfig,
        })
      ),
      InputConfig: text,
      OutputConfig: encodeURIComponent(
      JSON.stringify({
          bucket,
          object: `${ path }${ filename }`
        })
      )
    });
    console.log('res1', res1);
    // 2-智能配音任务是否完成【轮询】
    const jobId = res1.data.jobId;
    const getJobStatus = () => {
      return requestGet('GetSmartHandleJob', { // https://help.aliyun.com/document_detail/203429.html
        JobId: jobId,
      });
    };
    const shouldContinueGetJobStatus = (res) => {
      if (res.status !== 200 || res.data.state === 'Finished') return false;
      return true;
    };
    const {result: res2} = await poll(
      getJobStatus,
      shouldContinueGetJobStatus,
      2000,
      20000
    );

    // 3-智能配音任务完成则拉取生成的音频【轮询】
    if (res2.status === 200 && res2.data.state === 'Finished') {
      const mediaId = res2.data.output;

      const getProducedAudioInfo = () => {
        return request('GetMediaInfo', {
          MediaId: mediaId,
        });
      };
      const shouldContinueGetProducedAudioInfo = (res) => {
        if (res.status !== 200) return false;
        if (res.data?.mediaInfo?.mediaBasicInfo?.status === 'Normal') return false;
        return true;
      };
      const res3 = await poll(
        getProducedAudioInfo,
        shouldContinueGetProducedAudioInfo,
        5000,
        15000,
      );
      setLoadings(false)
      if (res3.timeout) {
        throw new Error('智能配音任务超时，请重新发起');
      } else {
        const result = transMediaList([res3.result.data.mediaInfo]); // transMediaList 同前文中的定义
        const newAudio = result[0];
        // 4-将新的音频素材与工程进行绑定
        await request('AddEditingProjectMaterials', {
          ProjectId: projectId,
          MaterialMaps: JSON.stringify({
            audio: newAudio.mediaId,
          }),
        });
        console.log('newAudio', newAudio);
        return newAudio;
      }
    } else {
      setLoadings(false)
      throw new Error(res2.data.ErrorMsg || '抱歉，暂未识别当前文字内容');
    }
  }
  const handleAudioPlay = (event, item, index) => {
    event.stopPropagation();
    setAuditionActive(index)
    const audioElement = myAudioRef.current;
    if (audioElement) {
      audioElement.src = item.mp3;
      audioElement.play();
      setIsPlaying(true);
    }
  };
  useEffect(() => {
    console.log('myAudioRef', myAudioRef);
    const audioElement = myAudioRef.current;

    if (audioElement) {
      const endedHandler = () => {
        setIsPlaying(false);
      };

      audioElement.addEventListener('ended', endedHandler);

      return () => {
        audioElement.removeEventListener('ended', endedHandler);
      };
    }
  }, []);
  return <GenerateWrapper>
    <div className='title_box'>生成语音</div>
    <div className='content_box'>
      <div className='audio_choose'>
        <div className='type_tabs_box'>
          <div>人声场景</div>
          {tables.map((item, index) => {
            return <div key={index} onClick={e => changeTabs(index)} className={index === tabsActive ? 'tabs_active' : ''}>{item}</div>
          })}
        </div>
        <div className='audio_list'>
          {audioList.map((item, index) => {
            return <div key={index} className='audio_item' onClick={e => changeAudioList(item, index) }>
              <div className={`img_box ${index === audioActive ? 'item-active' : ''}`}>
                <i className='iconfont icon-a-erji2' style={{ color: index === auditionActive && isPlaying ? '#1378f9' : '' }} onClick={e => handleAudioPlay(e, item, index)}></i>
                <img src={item.imgSrc} alt="" />
              </div>
              <div className='item_title'>{item.title}</div>
            </div>
          })}
          <audio ref={myAudioRef} crossOrigin="anonymous"></audio>
        </div>
      </div>
      <div className="parameter">
        <div className="parameter_title">参数设置</div>
        <div className='volume'>
          <div>音量</div>
          <Row>
            <Col span={20}>
              <Slider
                min={1}
                max={100}
                onChange={volumeChange}
                value={typeof volumeValue === 'number' ? volumeValue : 0}
                trackStyle={{ backgroundColor: '#1378f9' }}
                railStyle={{ backgroundColor: '#777777' }}
                handleStyle={{ borderColor: '#1378f9' }}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={1}
                max={100}
                style={{ margin: '0 16px' }}
                value={volumeValue}
                onChange={volumeChange}
              />
            </Col>
          </Row>
        </div>
        <div className='speech_rate'>
          <div>语速</div>
          <Row>
            <Col span={20}>
              <Slider
                min={-500}
                max={500}
                onChange={speechRateChange}
                value={typeof speechRate === 'number' ? speechRate : 0}
                trackStyle={{ backgroundColor: '#1378f9' }}
                railStyle={{ backgroundColor: '#777777' }}
                handleStyle={{ borderColor: '#1378f9' }}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={-500}
                max={500}
                style={{ margin: '0 16px' }}
                value={speechRate}
                onChange={speechRateChange}
              />
            </Col>
          </Row>
        </div>  
        <div className='intonation'>
          <div>语调</div>
          <Row>
            <Col span={20}>
              <Slider
                min={-500}
                max={500}
                onChange={intonationChange}
                value={typeof intonation === 'number' ? intonation : 0}
                trackStyle={{ backgroundColor: '#1378f9' }}
                railStyle={{ backgroundColor: '#777777' }}
                handleStyle={{ borderColor: '#1378f9' }}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={-500}
                max={500}
                style={{ margin: '0 16px' }}
                value={intonation}
                onChange={intonationChange}
              />
            </Col>
          </Row>
        </div>  
        <div className='bottom_button'>
          <Button type="primary" disabled={audioActive === null} loading={loadings} onClick={submitAudioProduceJob}>智能生成配音</Button>
          <Button onClick={e => changeShow(false)} style={{marginLeft: '20px'}}>关闭</Button>
        </div>
      </div>
    </div>
  </GenerateWrapper>
}
export default memo(GenerateSpeech)
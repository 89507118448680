import { useEffect, useState } from "react";
import { Modal, Pagination, Radio } from 'antd'
import { request, transMediaList } from "./utils";
import MediaItem from "./MediaItem";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Upload,Tabs } from "antd";
import { Checkbox} from 'antd';
import type { TabsProps } from 'antd';
import axios from "axios";


const options = [
  { label: "全部", value: "all" },
  { label: "视频", value: "video" },
  { label: "音频", value: "audio" },
  { label: "图片", value: "image" },
];

const items: TabsProps['items'] = [
  {
    key: '1',
    label: `资源`,
    children: ``,
  },
  {
    key: '2',
    label: `已选中`,
    children: ``,
  }
  
];
const PAGE_SIZE = 20;
const CheckboxGroup = Checkbox.Group;

function StickerModal(props) {
  const { onSubmit, onClose, projectId,tid,tiddata } = props;
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [tabMedia, setTabMedia] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [tabstatus, setTabstatus] = useState('one');
  const [mediaType, setMediaType] = useState(options[0].value);
  const [parentmediaType, setParentMediaType] = useState('all');
  const [status, setStatus] = useState("loading");
  // 定义了page变量，默认为1，可以使用setPage进行改变page的值
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [media, setMedia] = useState([]);
  const [fileList, setfileList] = useState([]);
  const [optionsX, setOptionsX] = useState([]);
  const [mediaTagId, setMediaTagId] = useState("");
  // const [nextToken, setNextToken] = '';
 
  useEffect(() => {
	 
    setStatus("loading");
	var data=[]
	tiddata.forEach(function(item,key,array){
		var litem=[]
		litem['mediaId']=item
	 	 data.push(litem)
	});
	setSelectedMedia(data)
	getStickerCategories_modle()
	tabMedialistsend(1)
	
	// return request("ListPublicMediaBasicInfos", {
	//   // https://help.aliyun.com/document_detail/209068.html
	//   IncludeFileBasicInfo: true,
	//   PageNo: page,
	//   PageSize: PAGE_SIZE,
	// }).then((res) => {
	//   const data = res.data.mediaInfos;
	//   const datalist=transMediaList(data)
	//   setStatus("done");
	//   console.log(data)
	//   setMedia(data)	
	//   setTotal(res.data.totalCount);
	//   // return transMediaList(data); // 需要做一些数据变换
	// });
	
   
   // }, [mediaType, page,projectId,tiddata]);// eslint-disable-line
   }, [mediaType,projectId,tiddata]);// eslint-disable-line
   useEffect(() => {
	   Medialistsend()
}, [mediaTagId,page]);// eslint-disable-line
	const getStickerCategories_modle = async () => {
			 const res = await request("ListAllPublicMediaTags", {
			   // https://help.aliyun.com/document_detail/207796.html
			   BusinessType: "sticker",
			   WebSdkVersion: window.AliyunVideoEditor.version,
			 });
			 const datalist=[];
			// const stickerCategories = res.data.mediaTagList.map((item,key) => ({
			// 	datalist[key]['label']= myLocale === "zh-CN"
			// 	   ? item.mediaTagNameChinese
			// 	   : item.mediaTagNameEnglish, // myLocale 是您期望的语言
			// 	datalist[key]['value']=item.mediaTagId
			// }));
			res.data.mediaTagList.forEach(function (item, key, array) {
				if(key===0){
					setMediaTagId(item.mediaTagId)
					// Medialistsend()
				}
				datalist[key]=[];
				datalist[key]['label']= item.mediaTagNameChinese
				
				datalist[key]['value']=item.mediaTagId
			});
			setOptionsX(datalist)
			
		   
			 // const stickerCategories = res.data.mediaTagList.map((item) => ({
			 //   id: item.mediaTagId,
			 //   name:
				//  myLocale === "zh-CN"
				//    ? item.mediaTagNameChinese
				//    : item.mediaTagNameEnglish, // myLocale 是您期望的语言
			 // }));
			 // return stickerCategories;
   };
	const tabMedialistsend = (page2) => {
		 request("ListPublicMediaBasicInfos", {
			  // https://help.aliyun.com/document_detail/209068.html
			  IncludeFileBasicInfo: true,
			  PageNo: page2,
			  PageSize: 100,
			}).then((res) => {
				
				if(page2<21) {
					var datalist=tabMedia;
					if(res.data.mediaInfos){
						res.data.mediaInfos.forEach((item) => {
						  if (tiddata.indexOf(item.mediaId) > -1) {
							  datalist.push(item)
							
						  }
						});
						setTabMedia(datalist)
						page2++
						tabMedialistsend(page2)
					}
				}
				
			 
			 
			});
			 
	};
	const Medialistsend = () => {
		
		 request("ListPublicMediaBasicInfos", {
			  // https://help.aliyun.com/document_detail/209068.html
			  IncludeFileBasicInfo: true,
			  PageNo: page,
			  PageSize: PAGE_SIZE,
			  MediaTagId:mediaTagId
			}).then((res) => {
			  const data = res.data.mediaInfos;
			  const datalist=transMediaList(data)
			  setStatus("done");
			  console.log(data)
			  setMedia(data)	
			  setTotal(res.data.totalCount);
			  // return transMediaList(data); // 需要做一些数据变换
			});
			 
	};
	
  const handleSubmit = async () => {
    setConfirmLoading(true);
    // 组装数据
    var valueObj = [];
	valueObj[tid]=[]
    selectedMedia.forEach((item) => {
      const mediaType = item.mediaType;
      const mediaId = item.mediaId;
	  if (!valueObj[tid].includes(mediaId)) {
		valueObj[tid].push(mediaId)
	  }
	  // valueObj[tid] += `,${mediaId}`;
    });
	props.click(valueObj)
	onSubmit()
   
  };

  const handleMediaTypeChange = (e) => {
    setMediaType(e.target.value);
    setPage(1);
  };
  const handleMediaTypexChange = (e) => {
    setMediaTagId(e);
    setPage(1);
	// Medialistsend()
  };
  const send_page=(p)=>{
	  setPage(p)
  };

  const handleClick = (item) => {
    const index = selectedMedia.findIndex((m) => m.mediaId === item.mediaId);
    if (index > -1) {
      setSelectedMedia(selectedMedia.filter((_, i) => i !== index));
    } else {
      setSelectedMedia([...selectedMedia, item]);
    }
	
	
	 var indexx = tabMedia.findIndex((m) => m.mediaId === item.mediaId);
	 var datalist=tabMedia
	
	if(indexx>-1){
		datalist.splice(indexx,1)
		setTabMedia(datalist);
	}else{
		datalist.push(item)
		setTabMedia(datalist);
	}
  };
 
  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);
  };
  const onTabChange = (key: string) => {
	  if(key==="1"){
		   setTabstatus('one')
		  
	  }else{
		  setTabstatus('two')
	  }
   
  };
  const selectedMediaIds = selectedMedia.map((m) => m.mediaId);
  return (
    <Modal
      open
      title="贴纸"
      onOk={handleSubmit}
      onCancel={onClose}
      width={720}
      // okButtonProps={{ disabled: selectedMedia.length === 0 }}
      okText="确定"
      cancelText="取消"
      confirmLoading={confirmLoading}
    >
	<Tabs defaultActiveKey="1" items={items}  onChange={onTabChange}/>
	{tabstatus==="one" &&
	<Tabs
		onChange={handleMediaTypexChange}
		defaultActiveKey="1"
		type="card"
		size='small'
		items={optionsX.map((item) => ({ key: item.value, label: item.label }))}
	/>
	}
	  {status === "done"&&tabstatus==="one" &&
	    (media.length ? (
	      <div style={{ display: "flex", flexWrap: "wrap" }} >
		  
	        {media.map((item) => (
			
	          <MediaItem
	            onClick={() => handleClick(item)}
	            selected={selectedMediaIds.indexOf(item.mediaId) > -1}
	            key={item.mediaId}
	            item={item}
				parentmediaType={parentmediaType}
	          />
	        ))}
			
	      </div>
	    ) : (
	      <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
	    ))}
		{status === "done"&&tabstatus==="two" &&
		  (tabMedia.length ? (
		    <div style={{ display: "flex", flexWrap: "wrap" }} >
				  
		      {tabMedia.map((item) => (
					
		        <MediaItem
		          onClick={() => handleClick(item)}
		          selected={selectedMediaIds.indexOf(item.mediaId) > -1}
		          key={item.mediaId}
		          item={item}
				  parentmediaType={parentmediaType}
		        />
		      ))}
					
		    </div>
		  ) : (
		    <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
		  ))}
	  {status === "loading" && (
	    <div style={{ height: "615px", textAlign: "center" }}>加载中...</div>
	  )}
	  {status === "error" && (
	    <div style={{ color: "red", height: "615px", textAlign: "center" }}>
	      加载出错
	    </div>
	  )}
	  {tabstatus==="one" &&
	  <Pagination
	    style={{ textAlign: "center" }}
	    defaultPageSize={PAGE_SIZE}
	    current={page}
	    total={total}
	    showSizeChanger={false}
	    onChange={(p) => send_page(p)}
	  />
	  }
	  
    </Modal>
  );
}

export default StickerModal;

// eslint-disable-next-line
import { useEffect, useState } from "react";
import { Modal, Pagination, Radio } from 'antd'
import { request, transMediaList } from "./utils";
import MediaItemnew from "./MediaItemnew";
import FlowerCaptionsModal from "./FlowerCaptionsModal";
import CopywritingModal from "./CopywritingModal";
import GenerateSpeech from "./components/generateSpeech/generateSpeech";
import { UploadOutlined, Icon } from "@ant-design/icons";
import { Button, Space, Upload,Input } from "antd";
import { Checkbox} from 'antd';
import axios from "axios";
import $ from 'jquery' ;

const { Search,TextArea } = Input;


function CaptionsModal(props) {
  const { onSubmit, onClose, projectId,tid,tiddata,captionsdata,onClickhzparent,setHzid,hzid } = props;
  const [selectedMedia, setSelectedMedia] = useState([]);
  // const [captionsdata, setCaptionsdata] = useState([]);
  
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [flowerCaptionsModal, setFlowerCaptionsModal] = useState(false);
  const [copywritingModal, setCopywritingModal] = useState(false);
  const [status, setStatus] = useState("loading");
  const [isShowGener, setIsShowGener] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  function changeSearchValue (e) {
	setSearchValue(e.target.value)
  }
  useEffect(() => {
    setStatus("loading");
	setSelectedMedia([...tiddata]);
	
	setStatus("done");
   }, [projectId,tiddata]);

  const handleSubmit = async () => {
    setConfirmLoading(true);
    // 组装数据
    var valueObj = [];
	valueObj[tid]=[]
    selectedMedia.forEach((item) => {
	  valueObj[tid].push(item)
	  // valueObj[tid] += `,${mediaId}`;
    });
	console.log(valueObj,"valueObj")
	
	props.click(valueObj)
	onSubmit()
   
  };
  const onBlur = (e) => {
	 
	  if(!e.target.value){
		  selectedMedia[e.target.id]=e.target.value
		  var data=[]
		selectedMedia.forEach(function(item) {
		   if(item){
			   data.push(item)
		   }
		})
		setSelectedMedia(data);
	  }else{
		  selectedMedia[e.target.id]=e.target.value
		  setSelectedMedia(selectedMedia);
	  }
  };
  const onjia = (e) => {
	setSelectedMedia([...selectedMedia, "智媒体剪辑"]);
  };
  const onClick = (e) => {
	  console.log("444446666")
	  setHzid(e)
  	setFlowerCaptionsModal(true)
  };
  const onClickwrit = (e) => {
  	  setHzid(e)
  	setCopywritingModal(true)
  };
 const onClickhz = (value) => {
	
	 onClickhzparent(value)
  	setFlowerCaptionsModal(true)
  };
  const onClickwritcallback = (value) => {
	  console.log(selectedMedia,"selectedMediaselectedMedia")
	  var data=selectedMedia
	  data[hzid]=value
	  setSelectedMedia(data);
	  console.log(selectedMedia)
	  
  	
  	 // onClickhzparent(value)
   	// setFlowerCaptionsModal(true)
   };

//    生成语音
function changeGenerateIsShow(value) {
	setIsShowGener(value)
}
const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
const boduStyle = {
	width: '720px',
		height: '427px',
		backgroundColor: '#141415'
}
  return (
    <Modal
      open
      title="字幕"
      onOk={handleSubmit}
      onCancel={onClose}
      width={780}
     
      okText="确定"
      cancelText="取消"
      confirmLoading={confirmLoading}
    >
	{isShowGener && <GenerateSpeech
		changeShow={changeGenerateIsShow}
		searchValue={searchValue}
	/>}
	{/* <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} bodyStyle={boduStyle}>
		<GenerateSpeech
		changeShow={changeGenerateIsShow}
	/>
      </Modal> */}
	{flowerCaptionsModal && (
	  <FlowerCaptionsModal
	  onSubmit={(info) => {
	  	console.log("关闭")
	    setFlowerCaptionsModal(false)
	   
	  }}
	  onClose={() => {
	    setFlowerCaptionsModal(false)
	   
	  }}
	  click={onClickhz}
	  tid={hzid}
	  hzid={hzid}
	  captionsdatach={captionsdata}
	  tiddata={captionsdata[tid]?(captionsdata[tid][hzid]?(captionsdata[tid][hzid][hzid]):[]):[]}
	  projectId={projectId}

	  />
	)}
	{copywritingModal && (
	  <CopywritingModal
	  onSubmit={(info) => {
	  	console.log("关闭")
	    setCopywritingModal(false)
	   
	  }}
	  onClose={() => {
	    setCopywritingModal(false)
	   
	  }}
	  click={onClickwritcallback}
	  tid={hzid}
	  tiddata={captionsdata[tid]?(captionsdata[tid][hzid]?(captionsdata[tid][hzid][hzid]):[]):[]}
	  projectId={projectId}
	
	  />
	)}
	 
	  {status === "done" &&
	    (selectedMedia.length ? (
	      <div style={{ display: "flex", flexWrap: "wrap",maxHeight: "50VH",overflowY: "scroll"}} >
		   {selectedMedia.map((item,key) => (
		// <Space direction="vertical" size="middle" style={{width:"100%"}} key={key}>
		// 	<Space.Compact style={{width:"100%"}}>
		// 		<Search placeholder="字幕" id={key} key={new Date().getTime()}  enterButton="+" onBlur={onBlur} onSearch={onjia} defaultValue={item} style={{padding:2}}  />
		// 		<Button type="primary"  style={{marginLeft:"10px"}} onClick={(e) => onClick(key)} >花字</Button>
		// 		<Button type="primary"  style={{marginLeft:"10px"}} onClick={(e) => onClickwrit(key)} >文案</Button>
				
		// 	</Space.Compact>
		// </Space> 
		// eslint-disable-next-line
		<Space direction="vertical" size="middle" style={{width:"100%",padding:"10px"}} key={key}>
			<Space.Compact style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
				<TextArea placeholder="字幕" id={key} key={new Date().getTime()}   onChange={onBlur} onSearch={onjia} defaultValue={item} style={{padding:2}}  />
				<Button type="primary" style={{marginLeft:"10px"}} onClick={onjia} >+</Button>
				<Button type="primary"  style={{marginLeft:"10px"}} onClick={(e) => onClick(key)} >花字</Button>
				<Button type="primary"  style={{marginLeft:"10px"}} onClick={(e) => onClickwrit(key)} >文案</Button>
				
			</Space.Compact>
		</Space> 
		  
		   ))}
			
	      </div>
	    ) : (
		
	      
		  // <Space direction="vertical" size="middle" style={{width:"100%"}}>
		  // 	<Space.Compact style={{width:"100%"}}>
		  // 		<Search placeholder="字幕" id="0" enterButton="+" onChange={onBlur} onSearch={onjia} value={searchValue} onChange={changeSearchValue} />
		  // 		<Button type="primary" style={{marginLeft:"10px"}} onClick={(e) => onClick(0)} >花字</Button>
				// <Button type="primary" style={{marginLeft:"10px"}} onClick={(e) => onClickwrit(0)} >文案</Button>
				// {/*<Button type="primary"  style={{marginLeft:"10px"}} onClick={(e) => changeGenerateIsShow(true)} >生成语音</Button>*/}
				// {/* <Button type="primary"  style={{marginLeft:"10px"}} onClick={showModal} >生成语音</Button> */}
		  // 	</Space.Compact>
		  // </Space> 
		  // eslint-disable-next-line
		  <Space direction="vertical" size="middle" style={{width:"100%"}}>
		  	<Space.Compact style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
		  		 <TextArea placeholder="字幕" id="0"  onBlur={onBlur} onSearch={onjia} value={searchValue} onChange={changeSearchValue} />
		  		<Button type="primary" style={{marginLeft:"10px"}} onClick={onjia} >+</Button>
				<Button type="primary" style={{marginLeft:"10px"}} onClick={(e) => onClick(0)} >花字</Button>
		  				<Button type="primary" style={{marginLeft:"10px"}} onClick={(e) => onClickwrit(0)} >文案</Button>
		  				{/*<Button type="primary"  style={{marginLeft:"10px"}} onClick={(e) => changeGenerateIsShow(true)} >生成语音</Button>*/}
		  				{/* <Button type="primary"  style={{marginLeft:"10px"}} onClick={showModal} >生成语音</Button> */}
		  	</Space.Compact>
		  </Space> 	
		  
	    ))}
		
	  {status === "loading" && (
	    <div style={{ height: "615px", textAlign: "center" }}>加载中...</div>
	  )}
	  {status === "error" && (
	    <div style={{ color: "red", height: "615px", textAlign: "center" }}>
	      加载出错
	    </div>
	  )}
	 
	  
    </Modal>
	
  );
}

export default CaptionsModal;

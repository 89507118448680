import axios from "axios";
import moment from "moment";

export  async function poll(fn, fnCondition, ms, timeout = 1000 * 60 * 30) {
   const startTime = moment();
  await new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
  let result = await fn();
  while (fnCondition(result)) {
    const currentTime = moment();
    if (currentTime - startTime > timeout - ms) {
      return {
        timeout: true,
        result,
      };
    }
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
    // eslint-disable-next-line no-await-in-loop
    result = await fn();
  }
  return {
    timeout: false,
    result,
  };
}

/**
 * 将服务端的素材信息转换成 WebSDK 需要的格式
 */
export function transMediaList (data) {
  if (!data) return []
  if (Array.isArray(data)) {
    return data.map((item) => {
      // console.log(item);
      const basicInfo = item.mediaBasicInfo
      const fileBasicInfo = item.fileInfoList[0].fileBasicInfo
      const mediaId = basicInfo.mediaId
      const result = {
        mediaId
      }
      const mediaType = basicInfo.mediaType
      result.mediaType = mediaType

      if (mediaType === 'video') {
        result.video = {
          title: fileBasicInfo.fileName,
          duration: Number(fileBasicInfo.duration),
          // 源视频的宽高、码率等数据，用于推荐合成数据，不传入或是0时无推荐数据
          width: Number(fileBasicInfo.width) || 0,
          height: Number(fileBasicInfo.height) || 0,
          bitrate: Number(fileBasicInfo.bitrate) || 0,
          // coverUrl: basicInfo.CoverURL
		   coverUrl: basicInfo.coverURL
        }
        const spriteImages = basicInfo.spriteImages
        
        if (spriteImages) {
          try {
            const spriteArr = JSON.parse(spriteImages)
            const sprite = spriteArr[0]
            const config = JSON.parse(sprite.Config)
            result.video.spriteConfig = {
              num: config.Num,
              lines: config.SpriteSnapshotConfig?.Lines,
              cols: config.SpriteSnapshotConfig?.Columns,
              cellWidth: config.SpriteSnapshotConfig?.CellWidth,
              cellHeight: config.SpriteSnapshotConfig?.CellHeight
            }
            result.video.sprites = sprite.SnapshotUrlList
          } catch (e) {
            console.log(e)
          }
        }
      } else if (mediaType === 'audio') {
        result.audio = {
          title: fileBasicInfo.fileName,
          duration: Number(fileBasicInfo.duration),
          coverURL: '' // 您可以给音频文件一个默认的封面图
        }
      } else if (mediaType === 'image') {
        result.image = {
          title: fileBasicInfo.fileName,
          coverUrl: fileBasicInfo.fileUrl,
          // 图片的宽高等数据，用于推荐合成数据，不传入或是0时无推荐数据
          width: Number(fileBasicInfo.width) || 0,
          height: Number(fileBasicInfo.height) || 0
        }
      }

      return result
    })
  } else {
    return [data]
  }
}





export function request (action, params) {
  return axios.post('https://yunhong.yinwukeji.com/agentadmin/Aliyun/' + action, params);
 }
 export function requestGet (action, params) {
   return axios.post('https://yunhong.yinwukeji.com/agentadmin/Aliyun/' + action, params);
  }
 // export function requeststoreadmin (action, params) {
 //   return axios.post('https://yunhong.yinwukeji.com/storeadmin/' + action, params);
 //  }
 export function requeststoreadmin (action, params) {
   return axios.post('https://yunhong.yinwukeji.com/agentadmin/Aliyun/' + action, params);
  }

export function formatTime (s) {
  const minutes = Math.floor(s / 60)
  const seconds = s - minutes * 60
  return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${Math.floor(seconds)}`
}
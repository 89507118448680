import { useEffect, useState } from "react";
import { Modal, Pagination, Radio, Checkbox, Button, Space, Upload, Switch, Tabs } from 'antd'
import MediaItem from "../../MediaItem";
import { request, transMediaList } from "../../utils";
import { getCategoryListAPI } from '../../api'
import { VideoejectModalWrapper } from './style'

const PAGE_SIZE = 20;
const CheckboxGroup = Checkbox.Group;

function VideoejectModal (props) {
	const { onSubmit, onClose, projectId, tid, tiddata, options, setTidaudiolist, tidaudiolist, storeid, agentid } = props;
	const [selectedMedia, setSelectedMedia] = useState([]);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [mediaType, setMediaType] = useState(options[0].value);
	const [status, setStatus] = useState("loading");
	// 定义了page变量，默认为1，可以使用setPage进行改变page的值
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [media, setMedia] = useState([]);
	const [fileList, setfileList] = useState([]); // 文件列表
	const [folderActive, setFolderActive] = useState(0) // 选中的文件夹
	const [folderId, setFolderId] = useState(0) // 选中的文件夹id
	// const [nextToken, setNextToken] = '';
	useEffect(() => {
		setStatus("loading");
		var data = []
		tiddata.forEach(function (item, key, array) {
			var litem = []
			litem['mediaId'] = item
			data.push(litem)
		});
		setSelectedMedia(data)

		return request("GetEditingProjectMaterials", {
			// https://help.aliyun.com/document_detail/209068.html
			ProjectId: projectId,
		}).then((res) => {
			const data = res.data.mediaInfos;
			const datalist = transMediaList(data)
			setStatus("done");
			if (folderId === 0) {
				console.log('选中全部-文件夹', folderId);
				setMedia(data)
			} else {
				console.log("筛选文件夹", folderId);
				const cloneData = data.filter(item => item.categoryid === folderId + '')
				setMedia(cloneData)
			}
			// return transMediaList(data); // 需要做一些数据变换
		});

	}, [mediaType, page, projectId, tiddata, folderId]);
	useEffect(() => {
		getCategoryList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const getCategoryList = async () => {
		const res = await getCategoryListAPI({
			storeid: storeid,
			agentid: agentid
		})
		setfileList([{
			name: '全部',
			id: 0
		}, ...res.data.rows])
	}
	// useEffect(() => {
	// 	console.log('folderId',folderId+'');

	// 	console.log(newData);
	// 	setMedia(newData)
	// }, [folderId])

	const handleSubmit = async () => {
		setConfirmLoading(true);
		// 组装数据
		var valueObj = [];
		valueObj[tid] = []
		selectedMedia.forEach((item) => {
			const mediaType = item.mediaType;
			const mediaId = item.mediaId;
			if (!valueObj[tid].includes(mediaId)) {
				valueObj[tid].push(mediaId)
			}
			// valueObj[tid] += `,${mediaId}`;
		});
		props.click(valueObj)
		onSubmit()

	};

	const handleMediaTypeChange = (e) => {
		console.log(e);
		setMediaType(e.target.value);
		setPage(1);
	};
	const changarraudio = (e) => {
		console.log(e);
		var value = 0
		if (e) {
			value = 1
		}
		var tidlistx = tidaudiolist
		tidlistx[tid] = value
		console.log(tidlistx, "setTidaudiolist");
		setTidaudiolist(tidlistx)

	};

	const handleClick = (item) => {
		// console.log(item);
		const index = selectedMedia.findIndex((m) => m.mediaId === item.mediaId);
		if (index > -1) {
			setSelectedMedia(selectedMedia.filter((_, i) => i !== index));
		} else {
			setSelectedMedia([...selectedMedia, item]);
			// console.log([...selectedMedia, item]);
		}
	};
	const selectedMediaIds = selectedMedia.map((m) => m.mediaId);
	return (
		<Modal
			open
			title="资源库"
			onOk={handleSubmit}
			onCancel={onClose}
			width={757}
			// okButtonProps={{ disabled: selectedMedia.length === 0 }}
			okText="确定"
			cancelText="取消"
			confirmLoading={confirmLoading}
		>
			<VideoejectModalWrapper>
				{/* <Radio.Group
					style={{ marginBottom: "20px" }}
					options={options}
					onChange={handleMediaTypeChange}
					value={mediaType}
					optionType="button"
					buttonStyle="solid"
				/> */}
				{options[0].value === "audio" && (
					<span style={{ marginBottom: '10px' }}>智能生成字幕
						<Space direction="vertical">
							<Switch checkedChildren="开启" unCheckedChildren="关闭" onChange={changarraudio} defaultChecked={tidaudiolist[tid] === '1' ? true : false} />
						</Space>
					</span>
				)}
				<div style={{marginBottom: '10px' , marginTop: '10px'}}>此处选择的素材时长请大于轨道上该处素材的时长，如果小于会出现生成的视频黑屏的情况</div>
				{status === "done" &&
					<div className="all_wrapper">
						<div className="left_wrapper">
							{fileList.map((item, i) => {
								return <div
									className={i === folderActive ? "classify_box class_active" : "classify_box"}
									key={i}
									onClick={() => {
										setFolderActive(i)
										setFolderId(item.id)
									}}
								>
									{item.name}
								</div>
							})}
						</div>
						<div className="right_wrapper">
							<Tabs
								onChange={handleMediaTypeChange}
								defaultActiveKey="1"
								type="card"
								size='small'
								items={options.map((item) => ({ key: item.value, label: item.label }))}
							/>
							{media.length ? (
								<div style={{ display: "flex", flexWrap: "wrap" }} >
									{media.map((item) => (
										<MediaItem
											onClick={() => handleClick(item)}
											selected={selectedMediaIds.indexOf(item.mediaId) > -1}
											key={item.mediaId}
											item={item}
											parentmediaType={mediaType}
											folderId={folderId}
										/>
									))}
								</div>
							) : (
								<div style={{ height: "500px", textAlign: "center" }}>暂无数据</div>
							)}
						</div>
					</div>
				}
				{status === "loading" && (
					<div style={{ height: "500px", textAlign: "center" }}>加载中...</div>
				)}
				{status === "error" && (
					<div style={{ color: "red", height: "615px", textAlign: "center" }}>
						加载出错
					</div>
				)}
			</VideoejectModalWrapper>
		</Modal>
	);
}

export default VideoejectModal;

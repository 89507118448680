import { formatTime } from './utils'

function MediaItem (props) {

  const { item, onClick, selected, parentmediaType, folderId } = props
  const mediaType = item.mediaBasicInfo.mediaType

  let coverUrl
  let title
  let duration = 0;
  let stylevalue

  if (mediaType === 'image') {
    coverUrl = item.fileInfoList[0].fileBasicInfo.fileUrl
    title = item.fileInfoList[0].fileBasicInfo.fileName
  } else {
    coverUrl = item.mediaBasicInfo.coverURL || 'https://img.alicdn.com/imgextra/i2/O1CN01fRTy3n1ZM1jvBOiyO_!!6000000003179-2-tps-240-136.png';
    title = item.fileInfoList[0].fileBasicInfo.fileName
    duration = item.fileInfoList[0].fileBasicInfo.duration
  }

  const style = {
    backgroundImage: `url(${coverUrl})`
  }

  if (mediaType === parentmediaType || parentmediaType === 'all') {
    stylevalue = {}
  } else if (item.categoryid && item.categoryid === folderId) {
    stylevalue = {}
  } else {
    stylevalue = {
      display: 'none'
    }
  }

  return (
    <div className={`item ${selected ? 'selected' : ''}`} onClick={onClick} style={stylevalue}>
      <div className='media_check' style={{ display: selected ? 'block' : 'none' }}> 已选择 </div>
      <div className='cover' style={style} />
      {mediaType !== 'image' && (
        <div className='duration'>{formatTime(duration)}</div>
      )}
      <div className='title'>{title}</div>
    </div>
  )
}

export default MediaItem

export const tongyong = [
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "小云，标准女声",
        "voice": 'xiaoyun',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/xiaoyun.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "小刚，标准男声",
        "voice": 'xiaogang',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/xiaogang.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "若兮，温柔女声",
        "voice": 'ruoxi',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/ruoxi.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "思琪，温柔女声",
        "voice": 'siqi',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/siqi.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "思佳，标准女声",
        "voice": 'sijia',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/sijia.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "思诚，标准男声",
        "voice": 'sicheng',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/sicheng.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾琪，温柔女声",
        "voice": 'aiqi',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aiqi.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾佳，标准女声",
        "voice": 'aijia',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aijia.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "艾诚，标准男声",
        "voice": 'aicheng',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aicheng.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "艾达，标准男声",
        "voice": 'aida',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aida.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "宁儿，标准女声",
        "voice": 'ninger',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/ninger.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "瑞琳，标准女声",
        "voice": 'ruilin',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/ruilin.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "柜姐，亲切女声",
        "voice": 'guijie',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/guijie.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Stella，知性女声",
        "voice": 'stella',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/stella.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "Stanley，沉稳男声",
        "voice": 'stanley',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/stanley.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "Kenny，沉稳男声",
        "voice": 'kenny',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/kenny.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Rosa，自然女声",
        "voice": 'rosa',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/rosa.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "马树，儿童剧男声",
        "voice": 'mashu',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/mashu.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "悦儿，儿童剧女声",
        "voice": 'yueer',
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/yueer.mp3'
    }
]
export const kefu = [
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "艾硕，自然男声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aishuo.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "思悦，温柔女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/siyue.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾雅，严厉女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aiya.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾夏，亲和女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aixia.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾美，甜美女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aimei.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾雨，自然女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aiyu.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾悦，温柔女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aiyue.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾婧，严厉女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aijing.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "小美，甜美女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/xiaomei.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾娜，浙普女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aina.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "佳佳，粤语女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/jiajia.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "桃子，粤语女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/taozi.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "大虎，东北话男声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/dahu.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "伊娜，浙普女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/yina.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "思婧，严厉女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/sijing.mp3'
    }
]
export const tonsheng = [
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "思彤，儿童音",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/sitong.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "小北，萝莉女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/xiaobei.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "艾彤，儿童音",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aitong.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾薇，萝莉女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aiwei.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "艾宝，萝莉女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aibao.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "杰力豆，治愈童声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/jielidou.mp3'
    }
]
export const yingwen = [
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Lydia，英中双语女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/lydia.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "Harry，英音男声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/harry.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Annie，美语女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/annie.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Abby，美音女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/abby.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "Andy，美音男声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/andy.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "Eric，英音男声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/eric.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "ava，美语女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/ava.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Emily，英音女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/emily.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Luna，英音女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/luna.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "Luca，英音男声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/luca.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Wendy，英音女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/wendy.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "William，英音男声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/william.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Olivia，英音女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/olivia.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "Becca，美语客服女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/becca.mp3'
    }
]
export const fangyan = [
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "小玥，四川话女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/xiaoyue.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "青青，中国台湾话女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/qingqing.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "翠姐，东北话女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/cuijie.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "小泽，湖南重口音男声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/xiaoze.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "姗姗，粤语女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/shanshan.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "艾侃，天津话男声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aikan.mp3'
    }
]
export const zhibo = [
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "老铁，东北老铁",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/laotie.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "老妹，吆喝女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/laomei.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "艾伦，悬疑解说",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/ailun.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "小仙，亲切女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/xiaoxian.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "猫小美，活力女声",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/maoxiaomei.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i1/O1CN01BvufN61xbeAtKT1nb_!!6000000006462-55-tps-28-28.svg",
        "title": "艾飞，激昂解说",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/aifei.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "巧薇，卖场广播",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/qiaowei.mp3'
    },
    {
        "imgSrc": "https://img.alicdn.com/imgextra/i2/O1CN01jAkoUe1o3uSShSAcB_!!6000000005170-55-tps-28-28.svg",
        "title": "亚群，卖场广播",
        "mp3": 'https://ice-pub-media.myalicdn.com/smart/smartVoice/yaqun.mp3'
    }
]
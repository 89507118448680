import { styled } from 'styled-components';
export const GenerateWrapper = styled.div`
    z-index: 999;
    color: #ffffffb8;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 720px;
    height: 427px;
    background-color: #141415;
    padding: 0 16px;
    font-size: 12px;
    .title_box {
        width: 100%;
        height: 48px;
        line-height: 48px;
    }

    .content_box {
        display: flex;
        .audio_choose {
            width: 338px;
            .type_tabs_box {
                display: flex;
                align-items: center;
                justify-content: space-around;
                .tabs_active {
                    background-color: #1378f9;
                    padding: 2px 6px;
                    border-radius: 100px;
                }
            }
            .audio_list {
                height: 300px;
                overflow: overlay;
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
                align-content: baseline;

                .audio_item {
                    margin: 0 10px 10px 0;
                    .item-active {
                        border: 1px solid #1378f9;
                    }
                    .img_box {
                        width: 96px;
                        height: 48px;
                        background-color: #2c2c2d;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        transition: all 0.3s;
                        > img {
                            width: 28px;
                            height: 28px;
                        }
                        .icon-a-erji2 {
                            position: absolute;
                            right: 6px;
                            top: 6px;
                            font-size: 18px;
                            height: 20px;
                            line-height: 20px;
                        }
                    }
                    .item_title {
                        width: 96px;
                        white-space:nowrap;
                        overflow:hidden;
                        text-overflow:ellipsis;
                    }
                }
                
            }
            .audio_list::-webkit-scrollbar {
                width: 8px; 
            }
            .audio_list::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #535353;
            }
            .audio_list::-webkit-scrollbar-track {
                background: transparent;
            }
        }
        .parameter {
            padding-left: 20px;
            width: 350px;
            position: relative;
            &_title {
                margin-bottom: 10px;
             }
            .volume,
            .speech_rate,
            .intonation {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
            }
            .bottom_button{
                position: absolute;
                bottom: 20px;
                left: 16px;
                display: flex;
                align-items: center;
            }
        }
    }
`
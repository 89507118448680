import { useEffect, useState } from "react";
import { Modal, Pagination, Radio } from 'antd'
import { request, transMediaList } from "./utils";
import MediaItemnew from "./MediaItemnew";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Upload,Tabs } from "antd";
import { Checkbox} from 'antd';
import type { TabsProps } from 'antd';
import axios from "axios";


const options = [
  { label: "全部", value: "all" },
  { label: "视频", value: "video" },
  { label: "音频", value: "audio" },
  { label: "图片", value: "image" },
];
const items: TabsProps['items'] = [
  {
    key: '1',
    label: `资源`,
    children: ``,
  },
  {
    key: '2',
    label: `已选中`,
    children: ``,
  }
  
];

const specialeffects = [
    { title: "对角切换", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/directional.webp" },
	{ title: "旋涡", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/displacement.webp" },
	{ title: "栅格", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/windowslice.webp" },
	{ title: "垂直领结", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/bowTieVertical.webp" },
	{ title: "水平领结", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/bowTieHorizontal.webp" },
	{ title: "放大消失", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/simplezoom.webp" },
	{ title: "水滴", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/waterdrop.webp" },
	{ title: "故障", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/glitchmemories.webp" },
	{ title: "波点", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/polka.webp" },
	{ title: "扭曲旋转", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/directionalwarp.webp" },
	{ title: "向上弹动", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/bounce_up.webp" },
	{ title: "向下弹动", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/bounce_down.webp" },
	{ title: "向右擦除", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/wiperight.webp" },
	{ title: "向左擦除", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/wipeleft.webp" },
	{ title: "向下擦除", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/wipedown.webp" },
	{ title: "向上擦除", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/wipeup.webp" },
	{ title: "雪花消除", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/morph.webp" },
	{ title: "圆形遮罩", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/circlecrop.webp" },
	{ title: "多格翻转", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/gridflip.webp" },
	{ title: "圆形放大", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/zoomincircles.webp" },
	{ title: "圆形扫描", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/radial.webp" },
	{ title: "线性溶解", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/crosshatch.webp" },
	{ title: "万花筒", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/kaleidoscope.webp" },
	{ title: "百叶窗", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/windowblinds.webp" },
	{ title: "蜂巢溶解", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/hexagonalize.webp" },
	{ title: "故障交替", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/glitchdisplace.webp" },
	{ title: "波纹", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/ripple.webp" },
	{ title: "风车", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/pinwheel.webp" },
	{ title: "时钟旋转", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/angular.webp" },
	{ title: "燃烧", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/burn.webp" },
	{ title: "椭圆遮罩", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/circle.webp" },
	{ title: "椭圆溶解", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/circleopen.webp" },
	{ title: "色相溶解", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/colorphase.webp" },
	{ title: "隧道扭曲", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/crosswarp.webp" },
	{ title: "立方体", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/cube.webp" },
	{ title: "渐变擦除", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/directionalwipe.webp" },
	{ title: "开幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/doorway.webp" },
	{ title: "渐隐", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/fade.webp" },
	{ title: "彩色渐隐", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/fadecolor.webp" },
	{ title: "灰色渐隐", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/fadegrayscale.webp" },
	{ title: "回忆", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/flyeye.webp" },
	{ title: "爱心遮罩", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/heart.webp" },
	{ title: "对角开幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/luma.webp" },
	{ title: "多层混合", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/multiplyblend.webp" },
	{ title: "像素溶解", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/pixelize.webp" },
	{ title: "花瓣遮罩", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/polarfunction.webp" },
	{ title: "随机方块", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/randomsquares.webp" },
	{ title: "旋转", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/rotatescalefade.webp" },
	{ title: "方块替换", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/squareswire.webp" },
	{ title: "向内推入", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/squeeze.webp" },
	{ title: "切入", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/swap.webp" },
	{ title: "线形擦除", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/transitions/wind.webp" },
];
const PAGE_SIZE = 20;
const CheckboxGroup = Checkbox.Group;

function TransitionModal(props) {
  const { onSubmit, onClose, projectId,tid,tiddata } = props;
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [mediaType, setMediaType] = useState(options[0].value);
  const [tabstatus, setTabstatus] = useState('one');
  const [tabMedia, setTabMedia] = useState([]);
  const [status, setStatus] = useState("loading");
  // 定义了page变量，默认为1，可以使用setPage进行改变page的值
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [media, setMedia] = useState([]);
  const [fileList, setfileList] = useState([]);
  // const [nextToken, setNextToken] = '';
  useEffect(() => {
	 
    setStatus("loading");
	setMedia(specialeffects);
	
	var data=[]
	tiddata.forEach(function(item,key,array){
		var litem=[]
		litem['coverUrl']=item
	 	 data.push(litem)
	});
	
	setSelectedMedia(data)

	setStatus("done");
	tabMedialistsend(1)
   }, [mediaType, page,projectId,tiddata]);// eslint-disable-line
   const tabMedialistsend = (page) => {
   	   var datalist=tabMedia
   		specialeffects.forEach((item) => {
   		  if (tiddata.indexOf(item.coverUrl) > -1) {
   			  datalist.push(item)
   			
   		  }
   		});
   		setTabMedia(datalist)
   		 
   };

  const handleSubmit = async () => {
    setConfirmLoading(true);
    // 组装数据
    var valueObj = [];
	valueObj[tid]=[]
    selectedMedia.forEach((item) => {
      const coverUrl = item.coverUrl;
	  if (!valueObj[tid].includes(coverUrl)) {
		valueObj[tid].push(coverUrl)
	  }
	  // valueObj[tid] += `,${mediaId}`;
    });
	props.click(valueObj)
	onSubmit()
   
  };

  const handleMediaTypeChange = (e) => {
    console.log(e);
    setMediaType(e.target.value);
    setPage(1);
  };

  const handleClick = (item) => {
    // console.log(item);
    const index = selectedMedia.findIndex((m) => m.coverUrl === item.coverUrl);
    if (index > -1) {
      setSelectedMedia(selectedMedia.filter((_, i) => i !== index));
    } else {
      setSelectedMedia([...selectedMedia, item]);
      // console.log([...selectedMedia, item]);
    }
	var indexx = tabMedia.findIndex((m) => m.coverUrl === item.coverUrl);
	 var datalist=tabMedia
	
	if(indexx>-1){
		datalist.splice(indexx,1)
		setTabMedia(datalist);
	}else{
		datalist.push(item)
		setTabMedia(datalist);
	}
  };
 
  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);
  };
  const onTabChange = (key: string) => {
  	  if(key==="1"){
  		   setTabstatus('one')
  		  
  	  }else{
  		  setTabstatus('two')
  	  }
   
  };
  const selectedMediaIds = selectedMedia.map((m) => m.coverUrl);
  return (
    <Modal
      open
      title="转场"
      onOk={handleSubmit}
      onCancel={onClose}
      width={780}
      // okButtonProps={{ disabled: selectedMedia.length === 0 }}
      okText="确定"
      cancelText="取消"
      confirmLoading={confirmLoading}
    >
	<Tabs defaultActiveKey="1" items={items}  onChange={onTabChange}/>
	  {status === "done"&&tabstatus==="one" &&
	    (media.length ? (
	      <div style={{ display: "flex", flexWrap: "wrap",height: "50VH",overflowY: "scroll"}} >
		  
	        {media.map((item,key) => (
			
	          <MediaItemnew
	            onClick={() => handleClick(item)}
	            selected={selectedMediaIds.indexOf(item.coverUrl) > -1}
				key={key}
	            
	            item={item}
	          />
	        ))}
			
	      </div>
	    ) : (
	      <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
	    ))}
		{status === "done"&&tabstatus==="two" &&
		  (tabMedia.length ? (
		    <div style={{ display: "flex", flexWrap: "wrap",overflowY: "scroll"}} >
				  
		      {tabMedia.map((item,key) => (
					
		        <MediaItemnew
		          onClick={() => handleClick(item)}
		          selected={selectedMediaIds.indexOf(item.coverUrl) > -1}
		          key={key}
		          item={item}
		        />
		      ))}
					
		    </div>
		  ) : (
		    <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
		  ))}
				
	  {status === "loading" && (
	    <div style={{ height: "615px", textAlign: "center" }}>加载中...</div>
	  )}
	  {status === "error" && (
	    <div style={{ color: "red", height: "615px", textAlign: "center" }}>
	      加载出错
	    </div>
	  )}
	 
	  
    </Modal>
  );
}

export default TransitionModal;

import { useEffect, useState } from "react";
import { Modal, Pagination, Radio } from 'antd'
import { request, transMediaList } from "./utils";
import MediaItemnew from "./MediaItemnew";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Upload,Tabs } from "antd";
import { Checkbox} from 'antd';
import type { TabsProps } from 'antd';
import axios from "axios";


const options = [
  { label: "全部", value: "all" },
  { label: "视频", value: "video" },
  { label: "音频", value: "audio" },
  { label: "图片", value: "image" },
];
const items: TabsProps['items'] = [
  {
    key: '1',
    label: `资源`,
    children: ``,
  },
  {
    key: '2',
    label: `已选中`,
    children: ``,
  }
  
];

const specialeffects = [
    { title: "开幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/open.webp" },
    { title: "闭幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/close.webp" },
    { title: "模糊", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/blur.webp" },
    { title: "轻微抖动", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/slightshake.webp" },
    { title: "镜头变焦", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/zoominout.webp" },
    { title: "电影感", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/movie.webp" },
	{ title: "轻微放大", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/zoomslight.webp" },
	{ title: "逐渐放大", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/zoom.webp" },
	{ title: "聚光灯打开", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/withcircleopen.webp" },
	{ title: "聚光灯关闭", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/withcircleclose.webp" },
	{ title: "聚光灯抖动", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/withcircleshake.webp" },
	{ title: "手电筒", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/withcircleflashlight.webp" },
	{ title: "滑动消失", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/disappear.webp" },
	{ title: "震惊", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/shock.webp" },
	{ title: "模糊开幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/bluropen.webp" },
	{ title: "模糊闭幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/blurclose.webp" },
	{ title: "咔嚓", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/photograph.webp" },
	{ title: "曝光降低", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/black.webp" },
	{ title: "渐变复古虚化", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/blurring.webp" },
	{ title: "彩色渐变黑白", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/color_to_grey.webp" },
	{ title: "黑白渐变彩色", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/grey_to_color.webp" },
	{ title: "方形开幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/slightrectshow.webp" },
	{ title: "缓慢清晰开幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/slightshow.webp" },
	{ title: "交叉开幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/wipecross.webp" },
	{ title: "渐显开幕", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/whiteshow.webp" },
	{ title: "画中画", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/image_in_image.webp" },
	{ title: "色差", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/color_difference.webp" },
	{ title: "闪白", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/white.webp" },
	{ title: "负片闪烁", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/minus_glitter.webp" },
	{ title: "抖动", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/jitter.webp" },
	{ title: "灵魂出窍", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/soulout.webp" },
	{ title: "扫描条纹", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/scanlight.webp" },
	{ title: "摇摆", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/swing.webp" },
	{ title: "闪屏", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/flashingscreen.webp" },
	{ title: "视频分割", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/segmentation.webp" },
	{ title: "霓虹灯", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/neolighting.webp" },
	{ title: "卷动", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/curl.webp" },
	{ title: "闪动", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/shine.webp" },
	{ title: "毛刺", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/smalljitter.webp" },
	{ title: "窗口过滤", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/windowblur.webp" },
	{ title: "窗格", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/windowblur2.webp" },
	{ title: "万花筒", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/kaleidoscope.webp" },
	{ title: "月亮投影", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/moon_projection.webp" },
	{ title: "星星投影", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/star_projection.webp" },
	{ title: "爱心投影", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/heart_projection.webp" },
	{ title: "夕阳投影", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/sunset_projection.webp" },
	{ title: "车窗投影", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/carwindow_projection.webp" },
	{ title: "闪烁的十字星", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/shinningstar_light.webp" },
	{ title: "天使光", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/anglelight.webp" },
	{ title: "若隐若现", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/fallingcircle.webp" },
	{ title: "中心光", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/lightcenter.webp" },
	{ title: "阳光经过", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/lightsweep.webp" },
	{ title: "荧幕照耀", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/rotationspotlight.webp" },
	{ title: "电视噪声", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/blackwhitetv.webp" },
	{ title: "雪花故障", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/oldtv.webp" },
	{ title: "老电视闪烁", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/oldtvshine.webp" },
	{ title: "夜视仪", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/nightvision.webp" },
	{ title: "彩色涟漪", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/colorfulripples.webp" },
	{ title: "水波荡漾", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/waterripple.webp" },
	{ title: "水中浸泡", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/simpleripple.webp" },
	{ title: "跑马灯", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/marquee.webp" },
	{ title: "动态分屏", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/livesplit.webp" },
	{ title: "二分屏", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/splitstill2.webp" },
	{ title: "三分屏", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/splitstill3.webp" },
	{ title: "四分屏", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/splitstill4.webp" },
	{ title: "九分屏", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/splitstill9.webp" },
	{ title: "六分屏", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/splitstill6.webp" },
	{ title: "黑白三格", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/blackwhitesplit.webp" },
	{ title: "模糊分屏", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/blurthreesplit.webp" },
	{ title: "炫彩", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/colorful.webp" },
	{ title: "颜色吞噬", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/blackfade.webp" },
	{ title: "移动彩虹色", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/movingrainbow.webp" },
	{ title: "鱼眼", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/fisheye.webp" },
	{ title: "马赛克", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/mosaic_rect.webp" },
	{ title: "全景图", coverUrl: "https://ice-pub-media.myalicdn.com/websdk/v2/effects/planet.webp" },
];
const PAGE_SIZE = 20;
const CheckboxGroup = Checkbox.Group;

function SpecialeffectsModal(props) {
  const { onSubmit, onClose, projectId,tid,tiddata } = props;
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [mediaType, setMediaType] = useState(options[0].value);
  const [tabstatus, setTabstatus] = useState('one');
  const [tabMedia, setTabMedia] = useState([]);
  const [status, setStatus] = useState("loading");
  // 定义了page变量，默认为1，可以使用setPage进行改变page的值
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [media, setMedia] = useState([]);
  const [fileList, setfileList] = useState([]);
  // const [nextToken, setNextToken] = '';
  useEffect(() => {
	 
    setStatus("loading");
	setMedia(specialeffects);
	
	var data=[]
	tiddata.forEach(function(item,key,array){
		var litem=[]
		litem['coverUrl']=item
	 	 data.push(litem)
	});
	
	setSelectedMedia(data)

	setStatus("done");
	tabMedialistsend(1)
   }, [mediaType, page,projectId,tiddata]);// eslint-disable-line
   const tabMedialistsend = (page) => {
   	   var datalist=tabMedia
   		specialeffects.forEach((item) => {
   		  if (tiddata.indexOf(item.coverUrl) > -1) {
   			  datalist.push(item)
   			
   		  }
   		});
   		setTabMedia(datalist)
   		 
   };

  const handleSubmit = async () => {
    setConfirmLoading(true);
    // 组装数据
    var valueObj = [];
	valueObj[tid]=[]
    selectedMedia.forEach((item) => {
      const coverUrl = item.coverUrl;
	  if (!valueObj[tid].includes(coverUrl)) {
		valueObj[tid].push(coverUrl)
	  }
	  // valueObj[tid] += `,${mediaId}`;
    });
	props.click(valueObj)
	onSubmit()
   
  };

  const handleMediaTypeChange = (e) => {
    console.log(e);
    setMediaType(e.target.value);
    setPage(1);
  };

  const handleClick = (item) => {
    // console.log(item);
    const index = selectedMedia.findIndex((m) => m.coverUrl === item.coverUrl);
    if (index > -1) {
      setSelectedMedia(selectedMedia.filter((_, i) => i !== index));
    } else {
      setSelectedMedia([...selectedMedia, item]);
      // console.log([...selectedMedia, item]);
    }
	var indexx = tabMedia.findIndex((m) => m.coverUrl === item.coverUrl);
	 var datalist=tabMedia
	
	if(indexx>-1){
		datalist.splice(indexx,1)
		setTabMedia(datalist);
	}else{
		datalist.push(item)
		setTabMedia(datalist);
	}
  };
 
  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);
  };
  const onTabChange = (key: string) => {
  	  if(key==="1"){
  		   setTabstatus('one')
  		  
  	  }else{
  		  setTabstatus('two')
  	  }
   
  };
  const selectedMediaIds = selectedMedia.map((m) => m.coverUrl);
  return (
    <Modal
      open
      title="特效"
      onOk={handleSubmit}
      onCancel={onClose}
      width={780}
      // okButtonProps={{ disabled: selectedMedia.length === 0 }}
      okText="确定"
      cancelText="取消"
      confirmLoading={confirmLoading}
    >
	<Tabs defaultActiveKey="1" items={items}  onChange={onTabChange}/>
	  {status === "done"&&tabstatus==="one" &&
	    (media.length ? (
	      <div style={{ display: "flex", flexWrap: "wrap",height: "50VH",overflowY: "scroll"}} >
		  
	        {media.map((item,key) => (
			
	          <MediaItemnew
	            onClick={() => handleClick(item)}
	            selected={selectedMediaIds.indexOf(item.coverUrl) > -1}
				key={key}
	            
	            item={item}
	          />
	        ))}
			
	      </div>
	    ) : (
	      <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
	    ))}
		{status === "done"&&tabstatus==="two" &&
		  (tabMedia.length ? (
		    <div style={{ display: "flex", flexWrap: "wrap",overflowY: "scroll"}} >
				  
		      {tabMedia.map((item,key) => (
					
		        <MediaItemnew
		          onClick={() => handleClick(item)}
		          selected={selectedMediaIds.indexOf(item.coverUrl) > -1}
		          key={key}
		          item={item}
		        />
		      ))}
					
		    </div>
		  ) : (
		    <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
		  ))}
				
	  {status === "loading" && (
	    <div style={{ height: "615px", textAlign: "center" }}>加载中...</div>
	  )}
	  {status === "error" && (
	    <div style={{ color: "red", height: "615px", textAlign: "center" }}>
	      加载出错
	    </div>
	  )}
	 
	  
    </Modal>
  );
}

export default SpecialeffectsModal;

import { useEffect, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
const { Dragger } = Upload;

/**
 * 
 * @param {folderID} param0 文件夹ID
 * @returns 
 */
const UploadComponent = ({ storeid, agentid, folderID }) => {
    const props = {
        name: 'file',
        multiple: true,
        listType: "picture",
        action: 'https://yunhong.yinwukeji.com/agentadmin/File/upload_ai',
        data: {
            storeid: storeid,
            agentid: agentid,
            categoryid: folderID
        },
        accept: "image/png,image/jpeg,audio/mpeg,audio/mp3,audio/wav,video/mp4",
        thumbUrl: "https://img0.baidu.com/it/u=741268616,1401664941&fm=253&fmt=auto&app=138&f=JPEG?w=748&h=500",
        showUploadList: {
            showRemoveIcon: false,
        }
    };
    return <Dragger {...props}>
        <p className="ant-upload-drag-icon">
            <InboxOutlined />
        </p>
        <p className="ant-upload-text">单击或拖动文件到此区域进行上传</p>
        <p className="ant-upload-hint">支持单个或批量文件，文件大小不能超过100兆</p>
    </Dragger>
}
export default UploadComponent
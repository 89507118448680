import { useEffect, useState } from "react";
import { Modal, Pagination, Radio } from 'antd'
import { request, transMediaList } from "./utils";
import MediaItemcopywriting from "./MediaItemcopywriting";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Upload,Tabs } from "antd";
import { Checkbox} from 'antd';
import type { TabsProps } from 'antd';
import axios from "axios";


const options = [
  { label: "全部", value: "all" },
  { label: "视频", value: "video" },
  { label: "音频", value: "audio" },
  { label: "图片", value: "image" },
];
const items: TabsProps['items'] = [
  {
    key: '1',
    label: `资源`,
    children: ``,
  },
  {
    key: '2',
    label: `已选中`,
    children: ``,
  }
  
];

const specialeffects = [
    
];
const PAGE_SIZE = 20;
const CheckboxGroup = Checkbox.Group;

function CopywritingModal(props) {
  const { onSubmit, onClose, projectId,tid,tiddata,hzid,captionsdatach } = props;
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [mediaType, setMediaType] = useState(options[0].value);
  const [tabstatus, setTabstatus] = useState('one');
  const [tabMedia, setTabMedia] = useState([]);
  const [status, setStatus] = useState("loading");
  // 定义了page变量，默认为1，可以使用setPage进行改变page的值
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [media, setMedia] = useState([]);
  const [fileList, setfileList] = useState([]);
  // const [nextToken, setNextToken] = '';
  useEffect(() => {
	 
    setStatus("loading");
	setMedia(specialeffects);
	
	var data=[]
	console.log(tiddata,"tiddatatiddata")
	
	tiddata.forEach(function(item,key,array){
		var litem=[]
		litem['coverUrl']=item
	 	 data.push(litem)
	});
	console.log(data,"datadatadata")
	
	setSelectedMedia(data)

	setStatus("done");
	// tabMedialistsend(1)
	return request("GetEditingProjectarrcopywriting", {
	  // https://help.aliyun.com/document_detail/209068.html
	  IncludeFileBasicInfo: true,
	  ProjectId:projectId,
	  PageNo: page,
	  PageSize: PAGE_SIZE,
	}).then((res) => {
	  const data = res.data.mediaInfos;
	  // const datalist=transMediaList(data)
	  setStatus("done");
	  console.log(data)
	  setMedia(data)	
	  setTotal(res.data.totalCount);
	  // return transMediaList(data); // 需要做一些数据变换
	});
	
   }, [mediaType, page,projectId,tiddata]);// eslint-disable-line
   const tabMedialistsend = (page) => {
   	   var datalist=tabMedia
   		specialeffects.forEach((item) => {
   		  if (tiddata.indexOf(item.coverUrl) > -1) {
   			  datalist.push(item)
   			
   		  }
   		});
   		setTabMedia(datalist)
   		 
   };

 //  const handleSubmit = async () => {
 //    setConfirmLoading(true);
 //    // 组装数据
 //    var valueObj = captionsdatach;
	// console.log(captionsdatach,"captionsdatachcaptionsdatach")
	// if(!valueObj[tid]){
	// 	valueObj[tid]=[];
		
	// }

 //    selectedMedia.forEach((item) => {
 //      const coverUrl = item.coverUrl;
	//   valueObj[tid][hzid]=coverUrl
 //    });
	// console.log(valueObj,"valueObjvalueObj")
	// props.click(valueObj)
	// onSubmit()
   
 //  };
 const handleSubmit = async () => {
   setConfirmLoading(true);
   // 组装数据
   var valueObj=selectedMediaIds[0]
   var callbackdata=""
	media.forEach((item) => {
		if(item.id===valueObj){
			 callbackdata=item.answer
			
		}
	})
 	props.click(callbackdata)
 	onSubmit()
  
 };
  const handleMediaTypeChange = (e) => {
    console.log(e);
    setMediaType(e.target.value);
    setPage(1);
  };

  const handleClick = (item) => {
    // console.log(item);
    const index = selectedMedia.findIndex((m) => m.id === item.id);
    if (index > -1) {
      setSelectedMedia(selectedMedia.filter((_, i) => i !== index));
    } else {
		var data=[];
		data.push(item)
      setSelectedMedia(data);
      // console.log([...selectedMedia, item]);
    }
	var indexx = tabMedia.findIndex((m) => m.id === item.id);
	 var datalist=[]
	
	if(indexx>-1){
		
		setTabMedia(datalist);
	}else{
		datalist.push(item)
		setTabMedia(datalist);
	}
  };
 
  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);
  };
  const onTabChange = (key: string) => {
  	  if(key==="1"){
  		   setTabstatus('one')
  		  
  	  }else{
  		  setTabstatus('two')
  	  }
   
  };
  const selectedMediaIds = selectedMedia.map((m) => m.id);
  console.log(selectedMediaIds,"selectedMediaIds")
  return (
    <Modal
      open
      title="文案"
      onOk={handleSubmit}
      onCancel={onClose}
      width={780}
      // okButtonProps={{ disabled: selectedMedia.length === 0 }}
      okText="确定"
      cancelText="取消"
      confirmLoading={confirmLoading}
    >
	
	  {status === "done"&&tabstatus==="one" &&
	    (media.length ? (
	      <div style={{ display: "flex", flexWrap: "wrap",height: "50VH",overflowY: "scroll"}} >
		  
	        {media.map((item,key) => (
			
	          <MediaItemcopywriting
	            onClick={() => handleClick(item)}
	            selected={selectedMediaIds.indexOf(item.id) > -1}
				key={key}
	            
	            item={item}
	          />
	        ))}
			
	      </div>
	    ) : (
	      <div style={{ height: "615px", textAlign: "center" }}>暂无数据</div>
	    ))}
		
				
	  {status === "loading" && (
	    <div style={{ height: "615px", textAlign: "center" }}>加载中...</div>
	  )}
	  {status === "error" && (
	    <div style={{ color: "red", height: "615px", textAlign: "center" }}>
	      加载出错
	    </div>
	  )}
	  {tabstatus==="one" &&
	  <Pagination
	    style={{ textAlign: "center",marginTop:"10px" }}
	    defaultPageSize={PAGE_SIZE}
	    current={page}
	    total={total}
	    showSizeChanger={false}
	    onChange={(p) => setPage(p)}
	  />
	  }
	 
	  
    </Modal>
  );
}

export default CopywritingModal;
